import { Injectable } from "@angular/core";
import { Actions, ofType, createEffect } from "@ngrx/effects";
import { from, lastValueFrom, of } from "rxjs";
import {
  catchError,
  filter,
  map,
  mergeMap,
  switchMap,
  withLatestFrom,
} from "rxjs/operators";
import * as productActions from "./product.actions";
import { ProductGraphQLService } from "@app/services/graphql/product/product.graphql.service";
import { Store, select } from "@ngrx/store";
import * as fromProducts from "./index";
import { LambdaService } from "@app/services/lambda/lambda.service";
import { environment } from "@environments/environment";
import { DeleteMode } from "@app/utils/enums";
import { IProduct, IProductSocialMediaInput } from "@app/models/product.model";
import { IProducer } from "@app/models/producer.model";
import {
  getCurrentSeason,
  getS3FolderNameForProduct,
} from "@app/utils/helpers";

@Injectable()
export class ProductEffects {
  getProductsByProducer$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(productActions.ProductActionTypes.GetProductsByProducer),
      switchMap((action: productActions.GetProductsByProducer) =>
        of(action).pipe(
          withLatestFrom(
            this._store.pipe(select(fromProducts.productsByProducerLoaded))
          )
        )
      ),
      filter(([_, loaded]) => !loaded),
      mergeMap(([{ payload }]) =>
        this._productGraphqlService.getAllByProducer(payload).pipe(
          map((data) => new productActions.GetProductsByProducerSuccess(data)),
          catchError((err) =>
            of(new productActions.GetProductsByProducerFailed(err))
          )
        )
      )
    );
  });

  createProduct$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(productActions.ProductActionTypes.CreateProduct),
      map((action: productActions.CreateProduct) => action),
      switchMap(({ payload, producer, implementation }) => {
        return this._productGraphqlService.createOne(payload).pipe(
          map((data) => {
            this.generateContentAndImages(
              data.id,
              payload,
              producer,
              implementation
            );
            return new productActions.CreateProductSuccess({
              ...payload,
              Id: data.id,
            });
          }),
          catchError((err) => of(new productActions.CreateProductFailed(err)))
        );
      })
    );
  });

  updateProduct$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(productActions.ProductActionTypes.UpdateProduct),
      map((action: productActions.UpdateProduct) => action),
      switchMap(({ payload }) => {
        return this._productGraphqlService.updateOne(payload).pipe(
          switchMap(() => {
            return of(
              new productActions.UpdateProductSuccess({
                ...payload,
              })
            );
          }),
          catchError((err) => of(new productActions.UpdateProductFailed(err)))
        );
      })
    );
  });

  regenerateProduct$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(productActions.ProductActionTypes.RegenerateProduct),
      map((action: productActions.RegenerateProduct) => action),
      switchMap(({ payload, producer, implementation }) => {
        return from(
          this.generateContentAndImages(
            payload.Id,
            payload,
            producer,
            implementation
          )
        ).pipe(
          map(() => {
            return new productActions.RegenerateProductSuccess({
              ...payload,
            });
          }),
          catchError((err) =>
            of(new productActions.RegenerateProductFailed(err))
          )
        );
      })
    );
  });

  deleteProductImagesInS3$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(productActions.ProductActionTypes.DeleteProductImagesInS3),
      map((action: productActions.DeleteProductImagesInS3) => action),
      switchMap(({ payload }) => {
        return from(this._lambdaService.deleteImagesFromS3(payload)).pipe(
          map(() => {
            return new productActions.DeleteProductImagesInS3Success({
              ...payload,
            });
          }),
          catchError((err) =>
            of(new productActions.DeleteProductImagesInS3Failed(err))
          )
        );
      })
    );
  });

  deleteProduct$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(productActions.ProductActionTypes.DeleteProduct),
      switchMap(({ payload }: productActions.DeleteProduct) =>
        this._productGraphqlService.deleteOne(payload).pipe(
          switchMap((data) => {
            //if the product is successfully deleted, delete all the images from S3 for that product
            if (
              payload.Photo ||
              (payload.GeneratedImages && payload.GeneratedImages.length)
            ) {
              const event = {
                FolderName: getS3FolderNameForProduct(
                  payload.Name,
                  payload.CreatedAt
                ),
                DeleteMode: DeleteMode.All,
                Bucket: environment.BucketName,
                Prefix: "Products/",
              };
              return of(
                new productActions.DeleteProductSuccess({ ...payload }),
                new productActions.DeleteProductImagesInS3(event)
              );
            }
            return of(new productActions.DeleteProductSuccess({ ...payload }));
          }),
          catchError((err) => of(new productActions.DeleteProductFailed(err)))
        )
      )
    );
  });

  /**
   * Generates social media content and images for a given product and producer.
   *
   * @param productId - The ID of the product.
   * @param product - The product details.
   * @param producer - The producer details.
   * @param implementation - The implementation version.
   * @returns A Promise that resolves when both API calls have completed.
   */

  generateContentAndImages = async (
    productId: string,
    product: IProduct,
    producer: IProducer,
    implementation: string
  ): Promise<void> => {
    const input: IProductSocialMediaInput = {
      ProductId: productId,
      ProductName: product.Name,
      Category: product.Category,
      ProductTags: product.Tags.join(","),
      ProductDescription: product.ShortDescription || "",
      ProductPhoto: product.Photo || "",
      ProducerName: producer.CompanyName,
      ProducerTags: producer.Tags.length ? producer.Tags.join(",") : "",
      ProducerDescription: producer.ShortDescription || "",
      ProducerLocation: "Sweden",
      Season: getCurrentSeason(),
      OrganizationNumber: producer.OrganizationNumber,
      Implementation: implementation,
    };
    await lastValueFrom(this._lambdaService.generateSocialMediaContent(input));
    await lastValueFrom(this._lambdaService.generateImages(input));
  };

  constructor(
    private _productGraphqlService: ProductGraphQLService,
    private _lambdaService: LambdaService,
    private _store: Store<any>,
    private _actions$: Actions
  ) {}
}
