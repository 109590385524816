import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaderResponse,
  HttpInterceptor,
  HttpProgressEvent,
  HttpRequest,
  HttpResponse,
  HttpSentEvent,
  HttpUserEvent,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, from } from "rxjs";
import { mergeMap, tap } from "rxjs/operators";
import { CognitoService } from "./cognito.service";

@Injectable()
export class CognitoInterceptor implements HttpInterceptor {
  constructor(private cognitoService: CognitoService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<
    | HttpSentEvent
    | HttpHeaderResponse
    | HttpProgressEvent
    | HttpResponse<any>
    | HttpUserEvent<any>
    | any
  > {
    return from(this.cognitoService.getUserToken("id_token")).pipe(
      mergeMap((token: any) => {
        if (token) {
          request = request.clone({
            setHeaders: {
              Authorization: token,
            },
          });
        }
        return next.handle(request);
      }),
      tap({
        error: (err) => {
          if (err instanceof HttpErrorResponse && err.status === 401) {
            this.cognitoService.signOut();
          }
        },
      })
    );
  }
}
