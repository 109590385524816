import { Component, HostListener, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { GenericValidator } from "@app/classes/GenericValidator/GenericValidator.class";
import { CognitoService } from "@app/services/cognito/cognito.service";
import { ToastrService } from "ngx-toastr";
import { Store, select } from "@ngrx/store";
import * as fromRoot from "@app/state/index";
import * as producerActions from "@app/state/producer/producer.actions";
import PasswordValidator from "@app/classes/PasswordValidator/PasswordValidator.class";
import { Subject, takeUntil } from "rxjs";
import * as fromProducers from "@app/state/producer";

@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
})
export class RegisterComponent implements OnInit, OnDestroy {
  userForm: any;
  validationMessages: { [key: string]: { [key: string]: string } };
  genericValidator: GenericValidator;
  passwordValidator: PasswordValidator;
  displayMessage: any = {};
  showPassword: boolean = false;
  showConfirmPassword: boolean = false;
  showVerifyMessage: boolean = false;
  private _ngDestroy$ = new Subject<void>();

  constructor(
    private store: Store<fromRoot.State>,
    private formBuilder: FormBuilder,
    private cognitoService: CognitoService,
    private router: Router,
    private toastr: ToastrService
  ) {
    this.validationMessages = {
      Email: {
        required: "Email is required",
        email: "Email must be a valid email address",
      },
      Password: {
        required: "Password is required",
        pattern:
          "Password should have at least 8 characters, one uppercase and one lowercase",
      },
      ConfirmPassword: {
        required: "Password confirmation is required",
      },
    };
    this.genericValidator = new GenericValidator(this.validationMessages);
    this.passwordValidator = new PasswordValidator();
  }

  ngOnInit(): void {
    this.userForm = this.formBuilder.group(
      {
        Email: ["", [Validators.required, Validators.email]],
        Password: [
          "",
          [
            Validators.required,
            Validators.pattern(this.passwordValidator.strongPasswordRegex),
          ],
        ],
        ConfirmPassword: ["", [Validators.required]],
      },
      {
        validators: this.passwordValidator.validate(
          "Password",
          "ConfirmPassword"
        ),
      }
    );
    this.displayMessages();
  }

  public displayMessages(): void {
    this.store
      .pipe(select(fromProducers.getSuccessMessage))
      .pipe(takeUntil(this._ngDestroy$))
      .subscribe((res) => {
        if (res) {
          this.toastr.success(res);
          this.store.dispatch(new producerActions.UpdateSuccessMessage(null));
        }
      });

    this.store
      .pipe(select(fromProducers.getErrorMessage))
      .pipe(takeUntil(this._ngDestroy$))
      .subscribe((res) => {
        if (res) {
          this.toastr.error(res);
          this.store.dispatch(new producerActions.UpdateErrorMessage(null));
        }
      });
  }

  public validateInput(): void {
    this.displayMessage = this.genericValidator.processMessages(this.userForm);
    if (this.userForm.errors && !this.userForm.errors["PasswordMatch"]) {
      this.displayMessage["ConfirmPassword"] = "Passwords missmatch";
    }
  }

  public async register(): Promise<void> {
    this.validateInput();
    if (this.userForm?.valid) {
      let newProducer = {
        Email: this.userForm.value.Email,
        Tags: [],
      };
      this.cognitoService
        .signUp(this.userForm)
        .then((result) => {
          this.store.dispatch(new producerActions.CreateProducer(newProducer));
          this.showVerifyMessage = true;
        })
        .catch((error) => {
          this.toastr.error(error);
        });
    }
  }

  public continueToUpdateProducer() {
    this.cognitoService
      .signIn(this.userForm)
      .then((result) => {
        this.router.navigate(["/update-producer"]);
      })
      .catch((error) => {
        this.toastr.error(error);
      });
  }

  //if the user reloads the page, give him a warning that the data may not be saved
  @HostListener("window:beforeunload", ["$event"])
  beforeUnloadHander($event: BeforeUnloadEvent) {
    if (this.showVerifyMessage) {
      $event.preventDefault();
      $event.returnValue = true;
    }
  }

  public navigateToLogin(): void {
    this.router.navigate(["login"]);
  }

  ngOnDestroy() {
    this._ngDestroy$.next();
    this._ngDestroy$.complete();
  }
}
