@if((currentProducer$|async) && ((currentProducer$|async).UpdatedAt ||
((currentProducer$|async).SocialMediaContents ||
(currentProducer$|async).GeneratedImages) )){
<div class="flex justify-between">
  <h1 class="mt-0 mb-1">Your products</h1>
  <button
    type="button"
    class="border border-solid border-white rounded-full text-[0] md:text-base md:rounded-lg bg-primary py-2 px-2 md:px-3 cursor-pointer text-white w-16 h-16 md:w-auto md:h-auto bottom-[8vh] right-[5vw] md:bottom-auto md:right-auto fixed md:relative"
    (click)="navigateToNewProduct()"
  >
    <i class="fas fa-plus text-[20px] md:text-base"></i> New product
  </button>
</div>
<div
  class="products-list grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 xl:grid-cols-5 lg:grid-cols-4 gap-6 mt-8 pb-16"
>
  @for (product of productsByProducer$ | async; track product.Id) {
  <div
    class="bg-white rounded-lg overflow-hidden border-solid border-1 border-[#dadada] cursor-pointer"
    (click)="viewProductDetails(product)"
  >
    @if(product.Photo){
    <img
      src="{{ product.Photo }}"
      class="w-full aspect-square"
      loading="lazy"
      alt="Product photo"
    />
    } @else{
    <div
      class="bg-[#f2f2f2] w-full flex items-center justify-center aspect-square"
    >
      No photo
    </div>
    }
    <div class="p-4">
      <p class="text-lg font-semibold mt-0 mb-0 text-gray-900">
        {{ product.Name }}
      </p>
      <p class="text-gray text-sm mb-0 mt-0">
        {{ calculateDaysSinceUpdate(product.UpdatedAt || product.CreatedAt) }}
      </p>
    </div>
  </div>
  }
</div>
}
