export const ProductCategoriesAndTags = {
  "Vegetables, Fruit, Berries, & Spices": [
    "Organic",
    "Handpicked",
    "Locally Produced",
    "Wild Berries",
    "Premium Choice",
    "Economical Choice",
  ],
  "Meat, Delicacies & Game": [
    "Organic",
    "Free-range",
    "Grass-fed",
    "Aged Meat",
    "Wild-caught",
    "Smoked",
  ],
  "Dairy Products, Eggs & Flour": [
    "Organic",
    "Free Outdoor",
    "Grass-based",
    "Lactose-free",
    "Gluten-free",
    "Omega-3",
  ],
  "Jam, Juice, Preserves & Honey": [
    "Organic",
    "Handpicked",
    "Locally Produced",
    "No Added Sugar",
    "Traditional Recipes",
    "Innovative Flavors",
  ],
  "Fish & Seafood": [
    "Sustainably Caught",
    "MSC Certified",
    "Premium Choice",
    "Omega-3",
    "Smoked",
    "In Season",
  ],
  Beverages: [
    "Organic",
    "Barrel",
    "Aged",
    "Fruity",
    "Traditionally Brewed",
    "Locally Produced",
    "Low Alcohol Content",
  ],
  "Health Products & Superfoods": [
    "Organic",
    "Antioxidant-rich",
    "High in Fiber",
    "Omega-3",
    "Raw Food",
    "Detox Aid",
  ],
  "Food Boxes & Bags": [
    "Organic",
    "Meat",
    "Fish",
    "Greens/Vegetables",
    "Season's Best",
    "Locally Produced",
  ],
  "Restaurants & Cafés": [
    "Organic",
    "Farm-to-table",
    "Family-friendly",
    "Romantic",
    "Vegetarian",
    "Outdoor Seating",
  ],
  "Events, Experiences & Product Launches": [
    "Corporate Events",
    "Product Launch",
    "Food & Beverage Tastings",
    "Workshops & Training",
    "Cultural Events",
    "Pop-up Events",
  ],
  Accommodation: [
    "B&B and Hotels",
    "Rooms, Miscellaneous",
    "Charming & Rural",
    "Activities & Experiences",
    "Family-friendly",
    "Historical Lodging",
  ],
  "Crafts & Souvenirs": [
    "Handmade",
    "Locally Made",
    "Traditional Craftsmanship",
    "Ethnic Patterns",
    "Artistic Creations",
    "Vintage and Antique",
  ],
  Miscellaneous: [
    "Organic",
    "Locally Produced",
    "Premium Choice",
    "Diversity",
    "Home and Decor",
    "Health and Well-being",
  ],
};

export const ProducerTags = [
  "Organic",
  "Sustainable",
  "Traditional",
  "Romantic",
  "Rustic",
  "Modern",
  "Family friendly",
  "Exclusive",
  "Vegetables",
  "Fruit",
  "Meat",
  "Dairy",
  "Fish & Seafood",
  "Beverages",
  "Health Products",
  "Restaurants & Cafés",
  "Events",
  "Accommodation",
  "Crafts & Souvenirs",
];
