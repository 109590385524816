import { GenericValidator } from "@app/classes/GenericValidator/GenericValidator.class";
import { CognitoService } from "@app/services/cognito/cognito.service";
import { ToastrService } from "ngx-toastr";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import PasswordValidator from "@app/classes/PasswordValidator/PasswordValidator.class";

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
})
export class ForgotPasswordComponent implements OnInit {
  validationMessages: { [key: string]: { [key: string]: string } };
  genericValidator: GenericValidator;
  passwordValidator: PasswordValidator;
  forgotPasswordForm: any;
  resetPasswordForm: any;
  displayMessage: any = {};
  showResetPasswordForm: boolean = false;
  showPassword: boolean = false;
  showConfirmPassword: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private cognitoService: CognitoService,
    private toastr: ToastrService
  ) {
    this.validationMessages = {
      Email: {
        required: "Email is required",
        email: "Email must be a valid email address",
      },
      NewPassword: {
        required: "Password is required",
        pattern:
          "Password should have at least 8 characters, one lowercase and one uppercase",
      },
      Code: {
        required: "Security code is required",
      },
      ConfirmNewPassword: {
        required: "Password confirmation is required",
      },
    };
    this.genericValidator = new GenericValidator(this.validationMessages);
    this.passwordValidator = new PasswordValidator();
  }

  ngOnInit(): void {
    this.forgotPasswordForm = this.formBuilder.group({
      Email: ["", [Validators.required, Validators.email]],
    });

    this.resetPasswordForm = this.formBuilder.group(
      {
        Code: ["", [Validators.required]],
        NewPassword: [
          "",
          [
            Validators.required,
            Validators.pattern(this.passwordValidator.strongPasswordRegex),
          ],
        ],
        ConfirmNewPassword: ["", [Validators.required]],
      },
      {
        validators: this.passwordValidator.validate(
          "NewPassword",
          "ConfirmNewPassword"
        ),
      }
    );
  }

  public validateInput(): void {
    if (this.showResetPasswordForm) {
      this.displayMessage = this.genericValidator.processMessages(
        this.resetPasswordForm
      );
      if (
        this.resetPasswordForm.errors &&
        !this.resetPasswordForm.errors["PasswordMatch"]
      ) {
        this.displayMessage["ConfirmNewPassword"] = "Passwords missmatch";
      }
    } else {
      this.displayMessage = this.genericValidator.processMessages(
        this.forgotPasswordForm
      );
    }
  }

  public forgotPassword(): void {
    this.validateInput();
    if (this.forgotPasswordForm.valid) {
      this.cognitoService
        .forgotPassword(this.forgotPasswordForm.value.Email)
        .then((result) => {
          this.showResetPasswordForm = true;
          this.toastr.success("Security code sent!");
        })
        .catch((error) => {
          this.showResetPasswordForm = false;
          this.toastr.error(error);
        });
    }
  }

  public resetPassword(): void {
    this.validateInput();
    if (this.resetPasswordForm.valid) {
      this.cognitoService
        .confirmPassword(
          this.forgotPasswordForm.value.Email,
          this.resetPasswordForm.value.Code,
          this.resetPasswordForm.value.NewPassword
        )
        .then((result) => {
          this.toastr.success("Password reset successfully!");
          this.router.navigate(["login"]);
        })
        .catch((error) => {
          this.toastr.error(error);
        });
    }
  }

  public navigateToLogin(): void {
    this.router.navigate(["login"]);
  }
}
