<div class="overflow-y-auto overflow-x-hidden p-1">
  <div class="flex items-center justify-center">
    <form
      [formGroup]="createProductForm"
      (ngSubmit)="createProduct()"
      class="w-full max-w-[600px]"
    >
      <h2 class="mt-0 mb-6 font-medium text-center">
        <span
          (click)="navigateToProducts()"
          class="text-[20px] mr-2 w-28 cursor-pointer"
        >
          <i class="fa fa-chevron-left"></i>
        </span>
        Skapa bilder och social mediatexter för din produkt / Generate content
        for your product
      </h2>
      <div class="mb-6">
        <label class="font-medium block text-sm mb-1" for="Name"
          >Namn på producten / Name of product</label
        >
        <input
          class="border border-solid rounded-lg w-full py-3 px-2 leading-tight placeholder-gray"
          type="text"
          autocomplete="on"
          id="Name"
          formControlName="Name"
          placeholder="Enter the name of the product"
          (keyup)="validateInput()"
          [ngClass]="
            displayMessage.Name
              ? 'border-danger outline-danger'
              : 'border-gray outline-zinc-600'
          "
          required
        />
        <span
          NgIf="displayMessage.Name"
          class="text-danger w-[29rem] text-sm block"
        >
          {{ displayMessage.Name }}
        </span>
      </div>

      <div class="mb-6">
        <label class="font-medium block text-sm mb-1" for="Category"
          >Product category</label
        >
        <select
          id="Category"
          formControlName="Category"
          required
          (change)="onCategoryChange()"
          class="border border-solid border-gray rounded-lg w-full py-3 px-2 leading-tight outline-zinc-600"
          [ngClass]="
            createProductForm.get('Category').value == ''
              ? 'text-gray'
              : 'text-black'
          "
        >
          <option value="">Select product category</option>
          <option *ngFor="let category of getCategories()">
            {{ category }}
          </option>
        </select>
        <span
          NgIf="displayMessage.Category"
          class="text-danger w-[29rem] text-sm block"
        >
          {{ displayMessage.Category }}
        </span>
      </div>

      <div class="mb-6">
        <div class="flex flex-center sm:flex-row md:flex-row lg:flex-row gap-4">
          <label class="font-medium block text-sm mb-1 w-1/2" for="Price">
            {{
              createProductForm.get("Category").value == "Accommodation"
                ? "Price/day"
                : "Price"
            }}
          </label>
          <label class="font-medium block text-sm mb-1 w-1/2" for="Unit"
            >Unit</label
          >
        </div>
        <div
          class="flex flex-center flex-row sm:flex-row md:flex-row lg:flex-row gap-4"
        >
          <input
            class="border border-solid rounded-lg w-full py-3 px-2 leading-tight placeholder-gray"
            type="number"
            id="Price"
            formControlName="Price"
            placeholder="€ 0.00"
            (keyup)="validateInput()"
            min="0"
            value="0"
            [ngClass]="
              displayMessage.Price
                ? 'border-danger outline-danger'
                : 'border-gray outline-zinc-600'
            "
            required
          />
          <select
            id="Unit"
            formControlName="Unit"
            class="border border-solid border-gray rounded-lg w-full py-3 px-2 leading-tight outline-zinc-600 text-black"
          >
            <option
              *ngFor="let unit of getProductUnits()"
              value="{{ unit }}"
              [selected]="unit === 'Kg'"
            >
              {{ unit }}
            </option>
          </select>
        </div>
        <span
          NgIf=" displayMessage.Price"
          class="text-danger w-[29rem] text-sm block"
        >
          {{ displayMessage.Price }}
        </span>
      </div>

      <div class="mb-6">
        <label class="font-medium block text-sm mb-1" for="Stock">Stock</label>
        <input
          class="border border-solid rounded-lg w-full py-3 px-2 leading-tight placeholder-gray"
          type="number"
          id="Stock"
          formControlName="Stock"
          placeholder="Enter stock"
          (keyup)="validateInput()"
          min="1"
          value="1"
          [ngClass]="
            displayMessage.Stock
              ? 'border-danger outline-danger'
              : 'border-gray outline-zinc-600'
          "
          required
        />
        <span
          NgIf="displayMessage.Stock"
          class="text-danger w-[29rem] text-sm block"
        >
          {{ displayMessage.Stock }}
        </span>
      </div>

      <div class="mb-5">
        <label class="font-medium block text-sm mb-1"
          >How would you describe your product?</label
        >
        @if(productTags.length){
        <div *ngFor="let tag of productTags" class="w-[29.5rem] inline">
          <button
            type="button"
            class="py-2 mb-2.5 mr-2.5 rounded-lg text-sm border border-solid bg-white text-normal font-bold text-center cursor-pointer"
            (click)="tag.active = !tag.active"
            [ngClass]="tag.active ? 'outline outline-zinc-600' : 'border-gray'"
          >
            {{ tag.name }}
          </button>
        </div>
        } @else{
        <p class="text-blue-500 w-[29rem] text-sm block">
          No category chosen. Please select one.
        </p>
        }
      </div>

      <div class="mb-6">
        <label class="font-medium block text-sm mb-2" for="ShortDescription"
          >Short Description (optional)</label
        >
        <textarea
          class="border border-solid rounded-lg w-full py-2 px-2 leading-tight font-sans border-gray outline-zinc-600 placeholder-gray"
          rows="7"
          placeholder="Describe your product"
          id="ShortDescription"
          formControlName="ShortDescription"
        ></textarea>
      </div>

      <div class="mb-6">
        <div class="flex flex-row justify-between w-full">
          <label class="font-medium text-sm mb-1" for="Photo"
            >Photo (optional)</label
          >
          <span
            *ngIf="productPhoto"
            (click)="removePhoto()"
            class="text-primary text-sm cursor-pointer"
            >Remove photo</span
          >
        </div>
        <div
          class="w-full h-32 rounded-lg border border-solid border-gray"
          onclick="document.getElementById('Photo').click()"
        >
          <img
            [src]=""
            id="photo-preview"
            class="w-full object-cover"
            [ngClass]="productPhoto ? 'h-32' : ''"
            alt=""
          />
          <div
            *ngIf="!productPhoto"
            class="flex flex-col items-center justify-center p-7"
          >
            <i class="fa fa-image text-gray text-lg"></i>
            <span class="text-primary text-sm cursor-pointer"
              >Select a file</span
            >
          </div>
          <input
            type="file"
            #fileUpload
            accept="image/*"
            id="Photo"
            name="photo"
            class="hidden"
            (change)="onPhotoSelected($event)"
          />
        </div>
      </div>

      <div class="mb-6">
        <label class="font-medium block text-sm mb-2" for="Implementation"
          >Select implementation</label
        >
        <div class="flex flex-center flex-col sm:flex-row gap-4">
          <div *ngFor="let impl of implementations" class="w-full">
            <button
              type="button"
              id="Implementation"
              class="w-full py-2 mb-2.5 rounded-lg text-sm border border-solid bg-white text-normal text-gray text-center cursor-pointer"
              (click)="selectedImpl = impl"
              [ngClass]="
                selectedImpl == impl
                  ? 'outline outline-zinc-600'
                  : 'border-gray'
              "
            >
              {{ impl }}
            </button>
          </div>
        </div>
      </div>

      <div class="mt-2">
        <button
          class="border border-solid block w-full rounded-lg bg-primary py-3 px-3 mb-2 cursor-pointer text-white"
          type="submit"
        >
          Generate
        </button>
      </div>
    </form>
  </div>
</div>
