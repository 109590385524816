import { Injectable } from "@angular/core";
import { IProducer } from "@app/models/producer.model";
import { Apollo } from "apollo-angular";
import { Observable } from "rxjs/index";
import { map } from "rxjs/operators";
import {
  CREATE_PRODUCER,
  CreateProducerResponse,
  PRODUCER_BY_EMAIL,
  ResponseProducerByEmail,
  UPDATE_PRODUCER,
  UpdateProducerResponse,
} from "./producer.graphql.queries";

@Injectable({ providedIn: "root" })
export class ProducerGraphQLService {
  constructor(private apollo: Apollo) {}

  createOne(
    input: IProducer
  ): Observable<CreateProducerResponse["newProducer"]> {
    return this.apollo
      .mutate<CreateProducerResponse>({
        mutation: CREATE_PRODUCER,
        variables: { input },
        fetchPolicy: "no-cache",
      })
      .pipe(map((res) => res.data?.newProducer));
  }

  updateOne(input: IProducer): Observable<string> {
    return this.apollo
      .mutate<UpdateProducerResponse>({
        mutation: UPDATE_PRODUCER,
        variables: { input },
        fetchPolicy: "no-cache",
      })
      .pipe(map((res) => res.data?.updateProducer));
  }

  getByEmail(Email: string): Observable<IProducer> {
    return this.apollo
      .query<ResponseProducerByEmail>({
        query: PRODUCER_BY_EMAIL,
        variables: { Email },
        fetchPolicy: "no-cache",
      })
      .pipe(map((res) => res.data?.getProducerByEmail));
  }
}
