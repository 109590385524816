<p class="text-xl pt-7 mb-2 font-bold text-turquoise">Kontakta oss:</p>
<span class="text-2xl pt-0">
  <a href="mailto:team@agrovia.ai" class="no-underline text-turquoise">
    {{ "team@agrovia.ai" }}
  </a>
</span>
<p class="text-4xl flex space-x-6 justify-center">
  <a
    href="https://www.instagram.com/agrovia_ai?igsh=M201dzZzNjRrbThx&utm_source=qr"
    class="text-turquoise"
    target="_blank"
  >
    <i class="fa-brands fa-instagram"></i>
  </a>
  <a
    href="https://www.tiktok.com/@agrovia.ai?_t=8pPcRq2gflB&_r=1"
    target="_blank"
    class="text-turquoise"
  >
    <i class="fa-brands fa-tiktok"></i>
  </a>
  <a
    href="https://www.facebook.com/profile.php?id=61564911585269"
    target="_blank"
    class="text-turquoise"
  >
    <i class="fa-brands fa-facebook"></i>
  </a>
  <a href="https://pin.it/2j5EmF9FB" target="_blank" class="text-turquoise">
    <i class="fa-brands fa-pinterest"></i>
  </a>
</p>
