import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { FormBuilder, Validators } from "@angular/forms";
import { CognitoService } from "@app/services/cognito/cognito.service";
import { GenericValidator } from "@app/classes/GenericValidator/GenericValidator.class";
import { ToastrService } from "ngx-toastr";
import PasswordValidator from "@app/classes/PasswordValidator/PasswordValidator.class";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
})
export class LoginComponent implements OnInit {
  userForm: any;
  validationMessages: { [key: string]: { [key: string]: string } };
  genericValidator: GenericValidator;
  passwordValidator: PasswordValidator;
  displayMessage: any = {};
  showPassword: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private cognitoService: CognitoService,
    private router: Router,
    private toastr: ToastrService
  ) {
    this.validationMessages = {
      Email: {
        required: "Email is required",
        email: "Email must be a valid email address",
      },
      Password: {
        required: "Password is required",
        pattern:
          "Password should have at least 8 characters, one lowercase and one uppercase",
      },
    };
    this.genericValidator = new GenericValidator(this.validationMessages);
    this.passwordValidator = new PasswordValidator();
  }

  ngOnInit(): void {
    this.userForm = this.formBuilder.group({
      Email: ["", [Validators.required, Validators.email]],
      Password: [
        "",
        [
          Validators.required,
          Validators.pattern(this.passwordValidator.strongPasswordRegex),
        ],
      ],
    });
  }

  public validateInput(): void {
    this.displayMessage = this.genericValidator.processMessages(this.userForm);
  }

  public showHidePassword(): void {
    this.showPassword = !this.showPassword;
  }

  public login(): void {
    this.validateInput();
    if (this.userForm?.valid) {
      this.cognitoService
        .signIn(this.userForm)
        .then((result) => {
          this.toastr.success("Successful login!");
          this.router.navigate(["products"]);
        })
        .catch((error) => {
          this.toastr.error(error);
        });
    }
  }

  public navigateToRegister(): void {
    this.router.navigate(["register"]);
  }

  public navigateToForgotPassword(): void {
    this.router.navigate(["forgot-password"]);
  }
}
