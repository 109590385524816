import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { GenericValidator } from "@app/classes/GenericValidator/GenericValidator.class";
import * as productActions from "@app/state/product/product.actions";
import { Store, select } from "@ngrx/store";
import * as fromRoot from "@app/state/index";
import { S3Service } from "@app/services/s3/s3.service";
import { Subject, takeUntil } from "rxjs";
import * as fromProducers from "@app/state/producer";
import { ProductCategoriesAndTags } from "@app/utils/categories-and-tags";
import { ProductUnits } from "@app/utils/units";
import moment from "moment";
import {
  convertFileToBase64,
  getS3FolderNameForProduct,
  getUniqueImageName,
} from "@app/utils/helpers";
import { ImplementationModel } from "@app/utils/enums";

@Component({
  selector: "app-new-product",
  templateUrl: "./new-product.component.html",
})
export class NewProductComponent implements OnInit, OnDestroy {
  createProductForm: FormGroup;
  productPhoto: File = null;
  genericValidator: GenericValidator;
  validationMessages: { [key: string]: { [key: string]: string } };
  displayMessage: any = {};
  currentProducer: any;
  productTags: any[] = [];
  implementations: string[] = [
    ImplementationModel.ChatGPTMidjourney,
    ImplementationModel.AmazonBedrock,
  ];
  selectedImpl: string;
  private _onDestroy$ = new Subject<void>();

  constructor(
    private store: Store<fromRoot.State>,
    private formBuilder: FormBuilder,
    private router: Router,
    private s3Service: S3Service
  ) {
    this.validationMessages = {
      Name: {
        required: "Name is required",
      },
      Category: {
        required: "Category is required",
      },
      Price: {
        required: "Price is required",
      },
      Stock: {
        required: "Stock is required",
      },
    };
    this.genericValidator = new GenericValidator(this.validationMessages);
  }

  ngOnInit(): void {
    this.createProductForm = this.formBuilder.group({
      Name: ["", [Validators.required]],
      Price: [0, [Validators.required]],
      Stock: [1, [Validators.required]],
      Unit: ["Kg"],
      ShortDescription: [""],
      Category: ["", [Validators.required]],
      Tags: [""],
    });
    this.store
      .pipe(
        select(fromProducers.getCurrentProducer),
        takeUntil(this._onDestroy$)
      )
      .subscribe((res) => {
        this.currentProducer = res;
      });
    this.selectedImpl = this.implementations[0];
  }

  public navigateToProducts(): void {
    this.router.navigate(["products"]);
  }

  public getCategories(): any {
    return Object.keys(ProductCategoriesAndTags);
  }

  public getProductUnits(): any {
    return ProductUnits.sort((a, b) => {
      return a > b ? 1 : a < b ? -1 : 0;
    });
  }

  public onCategoryChange() {
    this.validateInput();
    if (this.createProductForm.value.Category) {
      this.productTags = ProductCategoriesAndTags[
        this.createProductForm.value.Category
      ].map((tag) => {
        return {
          name: tag,
          active: false,
        };
      });
    } else {
      this.productTags = [];
    }
  }

  public validateInput(): void {
    this.displayMessage = this.genericValidator.processMessages(
      this.createProductForm
    );
  }

  public onPhotoSelected(event): void {
    if (event && event.target && event.target.files && event.target.files[0]) {
      this.productPhoto = event.target.files[0];
      var photo_preview = document.getElementById("photo-preview");
      photo_preview["src"] = URL.createObjectURL(event.target.files[0]);
      event.target.value = "";
    }
  }

  public removePhoto(): void {
    this.productPhoto = null;
    var photo_preview = document.getElementById("photo-preview");
    photo_preview["src"] = "";
  }

  async createProduct(): Promise<void> {
    this.validateInput();
    if (this.createProductForm.valid) {
      let selectedPhoto = null;
      const currentTime = moment().format();
      if (this.productPhoto && this.productPhoto instanceof File) {
        const base64String = await convertFileToBase64(this.productPhoto);
        //set the image name to be timestamp.[jpg,jpeg,png,etc depending of the chosen image]
        const imageName = getUniqueImageName(this.productPhoto.name);
        const subFolderName = getS3FolderNameForProduct(
          this.createProductForm.value.Name,
          currentTime
        );
        selectedPhoto = await this.s3Service
          .imageUpload(base64String, "Products", subFolderName, imageName)
          .then((image) => {
            return image;
          });
      }
      const newProduct = {
        OrganizationNumber: this.currentProducer.OrganizationNumber,
        Name: this.createProductForm.value.Name,
        Price: Number(this.createProductForm.value.Price.toFixed(2)),
        Stock: Number(this.createProductForm.value.Stock.toFixed(2)),
        Unit: this.createProductForm.value.Unit,
        Category: this.createProductForm.value.Category,
        Tags: this.productTags.reduce((filtered, tag) => {
          if (tag.active) {
            filtered.push(tag.name);
          }
          return filtered;
        }, []),
        ShortDescription: this.createProductForm.value.ShortDescription || null,
        Photo: selectedPhoto,
        SocialMediaContents: [],
        GeneratedImages: [],
        CreatedAt: currentTime,
        Implementation: this.selectedImpl,
      };
      this.store.dispatch(
        new productActions.CreateProduct(
          newProduct,
          this.currentProducer,
          this.selectedImpl
        )
      );
      this.router.navigate(["products"]);
      setTimeout(() => {
        alert(
          `The social media content and images for product ${newProduct.Name} are being created in the background. You will be notified when they will be created!\n\nNote: If you do not receive 5 Social media texts and 12 images, please click the 'Regenerate' button.`
        );
      }, 1000);
    }
  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
