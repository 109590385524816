<div class="flex justify-center">
  <div class="text-center w-[60%] intro-text">
    <!-- Swedish version of the text -->
    <h1 class="mb-0 text-orange text-4xl font-normal">
      För rättvisa priser, marginaler och en hållbar framtid
    </h1>
    <p class="text-19px leading-7">
      Höj ditt jordbruk och hantverk till nästa nivå. Med agrovia.ai
      demokratiserar vi de verktyg som tidigare varit reserverade för
      storbolagen. Med kraften från AI kan du också nå ut bredare och sälja mer
      – inte bara för att öka din försäljning och förbättra dina egna
      marginaler, utan också för att stödja den lokala gemenskapen och naturen.
    </p>
    <h3 class="text-orange text-2xl pt-7 font-normal">
      Och det bästa av allt? agrovia.ai är gratis
    </h3>
    <p class="text-19px leading-7">
      Enkelhet och tillgänglighet: Det enda du behöver är en verifierad
      e-postadress. Du behöver inte kunna något om AI eller marknadsföring.
      agrovia.ai hjälper dig enkelt att skapa loggor, webbtexter och allt dina
      produkter behöver för att synas på sociala medier: texter, emojis,
      hashtags och bilder.
    </p>
    <h3 class="text-orange text-2xl pt-7 font-normal">
      Agrovia.ai lyfter fram dina lokala produkter och hantverk – gör dem
      synliga så att de kan nå ut och uppskattas över hela landet och
      internationellt
    </h3>
    <p class="italic text-19px leading-7 pt-7">
      Observera att detta är en applikation under utveckling, och förbättringar
      sker ständigt. Den svarar just nu enbart på svenska men utökad språkbruk
      är under utveckling. Ibland kan också AIn vara lika lat som drängen som
      också hade en dräng.😊
    </p>
    <h3 class="text-orange text-2xl pt-7 font-normal">
      Skapat för småproducenter
    </h3>
    <p class="text-19px leading-7">
      Agrovia.ai är utvecklat för dig som vill skapa en rättvisare värld genom
      ditt arbete med högkvalitativa råvaror, driver ett café eller B&B,
      tillverkar hantverksföremål, håller kurser och mycket mer. Vi förstår att
      du inte alltid har tid att skriva engagerande texter för sociala medier
      eller ta de perfekta bilderna och det är här agrovia.ai hjälper dig.
    </p>
    <h3 class="m-0 text-orange text-2xl pt-7 font-normal">
      Fördelar med agrovia.ai
    </h3>
    <h4 class="mb-0 pb-0 text-19px">Enkelhet och effektivitet:</h4>
    <p class="text-19px leading-7">
      agrovia.ai låter dig använda AI-teknologin utan att behöva veta hur AI
      fungerar. Fyll bara i namnet på din produkt, välj några produktbeskrivande
      taggar, och du har genast färdiga texter och bilder redo att användas. Och
      bäst av allt – det är helt gratis.
    </p>
    <h4 class="mb-0 pb-0 text-19px">
      Gratis AI-kraft för kommunikation och försäljning:
    </h4>
    <p class="text-19px leading-7">
      Med agrovia.ai demokratiseras de kraftfulla verktyg som tidigare bara
      storbolagen haft tillgång till. Vi gör det möjligt för småproducenter att
      nå ut på ett hållbart sätt.
    </p>
    <h4 class="mb-0 pb-0 text-19px">Logistik och distribution:</h4>
    <p class="text-19px leading-7">
      Vi hjälper lokala producenter och bönder att effektivisera sin logistik
      och distribution på ett mer hållbart sätt (kommer i Q4-24 i Sverige).
    </p>
    <h4 class="mb-0 pb-0 text-19px">Multikanalförsäljning med agrovia.ai:</h4>
    <p class="text-19px leading-7">
      Vi börjar med att förstå ditt företag och dina produkter. Våra avancerade
      AI-modeller skapar sedan bilder och skriver säljande och beskrivande
      texter som passar perfekt för alla dina sociala mediekanaler och
      e-handelsplattformar. Dessutom hjälper vi dig att utveckla en egen
      e-handelsapplikation där alla AI-genererade bilder, texter och
      produktinformation automatiskt integreras, vilket gör det enkelt att sälja
      dina produkter över flera kanaler samtidigt (kommer i Q4).
    </p>

    <app-social-media></app-social-media>

    <div class="bg-turquoise h-[3px] mt-[3.125rem] mb-[3.125rem]"></div>

    <!-- English version of the text -->
    <h1 class="mb-0 text-orange">
      For Fair Prices, Margins, and a Sustainable Future
    </h1>
    <p class="text-19px leading-7">
      Elevate your farming and craftsmanship to the next level. At agrovia.ai,
      we’re democratizing tools that were once reserved for large corporations.
      Thanks to the power of AI, you can now reach a wider audience and sell
      more—not just to increase your sales and margins, but also to support
      local communities and the environment.
    </p>
    <h3 class="text-orange text-2xl pt-7 font-normal">
      And the best part? agrovia.ai is free
    </h3>
    <p class="text-19px leading-7">
      Simplicity and accessibility: All you need is a verified email address.
      You don’t need to know anything about AI or marketing. agrovia.ai makes it
      easy for you to create logos, web copy, and everything your products need
      to stand out on social media—from texts, emojis, and hashtags to images.
    </p>
    <h3 class="text-orange text-2xl pt-7 font-normal">
      Agrovia.ai puts your local products and craftsmanship in the spotlight,
      making them visible and appreciated across the country—and even
      internationally
    </h3>
    <p class="text-19px leading-7 italic pt-7">
      Please note: This application is under development, and improvements are
      ongoing. For now, it only responds in Swedish, but more languages are on
      the way. And hey, sometimes the AI can be as lazy as the farmhand who
      needed his own farmhand.😊
    </p>
    <h3 class="text-orange text-2xl pt-7 font-normal">
      Created for Small Producers
    </h3>
    <p class="text-19px leading-7">
      Agrovia.ai is designed for those of you working with high-quality raw
      materials to create a fairer world, whether you run a café, a B&B, produce
      handcrafted items, teach courses, or more. We get it—you don’t always have
      time to write engaging social media posts or take perfect pictures. That’s
      where agrovia.ai comes in.
    </p>
    <h3 class="text-orange text-2xl pt-7 font-normal">
      Advantages of agrovia.ai
    </h3>
    <h4 class="mb-0 pb-0 text-19px">Simplicity and Efficiency:</h4>
    <p class="text-19px leading-7">
      agrovia.ai lets you harness AI technology without needing to understand
      how AI works. Simply enter the name of your product, choose a few
      descriptive tags, and instantly get ready-to-use texts and images. And the
      best part? It’s completely free.
    </p>
    <h4 class="mb-0 pb-0 text-19px">
      Free AI Power for Communication and Sales:
    </h4>
    <p class="text-19px leading-7">
      With agrovia.ai, we’re leveling the playing field. The powerful tools once
      available only to big corporations are now in the hands of small
      producers, enabling sustainable growth and outreach.
    </p>
    <h4 class="mb-0 pb-0 text-19px">Logistics and Distribution:</h4>
    <p class="text-19px leading-7">
      We help local producers and farmers streamline their logistics and
      distribution in a more sustainable way (coming Q4-24 in Sweden).
    </p>
    <h4 class="mb-0 pb-0 text-19px">Multichannel Selling with agrovia.ai:</h4>
    <p class="text-19px leading-7">
      We start by understanding your business and products. Our advanced AI
      models then create images and write compelling texts, perfectly suited for
      all your social media channels and e-commerce platforms. Plus, we help you
      develop your own e-commerce app, where all AI-generated images, texts, and
      product information are automatically integrated, making it easy to sell
      across multiple channels at once (coming Q4).
    </p>

    <app-social-media></app-social-media>

    <div class="bg-turquoise h-[3px] mt-[3.125rem] mb-5"></div>
  </div>
</div>
