export const ProductUnits = [
  "Kg",
  "Liter",
  "Styck",
  "Låda/Paket/Bunt",
  "Flaska/Burk",
  "Rum/natt",
  "Deltagare",
  "Timme",
];
