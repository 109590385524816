import { Season } from "./enums";

/**
 * Converts a File object to a Base64 string.
 * @param file - The file to convert.
 * @returns A promise that resolves to a Base64 string.
 */
export const convertFileToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result as string);
    };
    reader.onerror = (error) => {
      reject(error);
    };
    reader.readAsDataURL(file);
  });
};

/**
 * Returns unique name for a image.
 * @param imageName - The name of the uploaded image.
 * @returns A string.
 */
export const getUniqueImageName = (imageName: string): string => {
  return new Date().getTime() + "." + imageName.split(".").pop();
};

/**
 * Returns the folder name in S3 where the product images are stored.
 * @param name - the name of the product.
 * @param creationDate - the creation date of the product.
 * @returns A string.
 */
export const getS3FolderNameForProduct = (
  name: string,
  creationDate: string
): string => {
  return name + "-" + creationDate.replace(/[:+]/g, "-");
};

/**
 * Returns the current season based on the current date.
 * Seasons are determined by specific date ranges:
 * - Winter: December 21 - March 19
 * - Spring: March 20 - June 20
 * - Summer: June 21 - September 21
 * - Autumn: September 22 - December 20
 *
 * @returns {Season} The current season as an enum.
 */
export function getCurrentSeason(): Season {
  const now = new Date();
  const month = now.getMonth(); // 0 (Jan) - 11 (Dec)
  const day = now.getDate();

  if ((month === 11 && day >= 21) || month <= 1 || (month === 2 && day < 20)) {
    return Season.Winter;
  } else if (
    (month === 2 && day >= 20) ||
    month <= 4 ||
    (month === 5 && day < 21)
  ) {
    return Season.Spring;
  } else if (
    (month === 5 && day >= 21) ||
    month <= 7 ||
    (month === 8 && day < 22)
  ) {
    return Season.Summer;
  } else {
    return Season.Autumn;
  }
}
