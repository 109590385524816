@if((currentProducer$|async) && ((currentProducer$|async).UpdatedAt ||
((currentProducer$|async).SocialMediaContents ||
(currentProducer$|async).GeneratedImages) )){
<div
  class="flex flex-col md:flex-row bg-gray-200 h-screen -ml-2 overflow-hidden"
>
  <!--Toolbar section-->
  <div
    class="fixed z-20 top-0 left-0 right-0 border-0 border-b-[1px] border-gray border-solid h-[60px] flex items-center justify-between md:hidden pt-2 bg-white"
  >
    <button
      type="button"
      (click)="showMenu = !showMenu"
      class="w-[50px] border-0 bg-white cursor-pointer"
      aria-label="navBarMenu"
      #navbarMenuButton
    >
      <img
        class="w-full aspect-[48/48]"
        src="../assets/images/icon-nav.png"
        alt=""
      />
    </button>
    <img
      src="../assets/images/logo.png"
      alt="Logo"
      (click)="navigateToHomePage()"
      class="w-24 object-contain object-center flex-1 max-h-[46px] aspect-[100/50]"
    />
    <div class="w-[50px]"></div>
  </div>
  <!--Sidebar section-->
  <div
    [ngClass]="showMenu ? 'menu-open' : 'menu-close'"
    class="bg-black text-white w-64 flex-col flex z-20"
    id="navbar"
  >
    <div
      class="hidden md:flex items-center justify-center mx-3 mt-8 h-10 pb-10 bg-gray-900"
    >
      <img
        src="../assets/images/logo-white.png"
        alt="Logo here"
        class="h-14 aspect-[150/70]"
        loading="lazy"
        (click)="navigateToHomePage()"
      />
    </div>
    <!--Producer Account Section-->
    <div class="mx-4 pt-3 relative">
      <nav
        class="flex items-center rounded-md text-white cursor-pointer"
        title="{{ (currentProducer$ | async).CompanyName }}"
        (click)="toggleAccount()"
        [class.bg-zinc-700]="showAccount"
      >
        <span class="block py-2 rounded text-gray">
          <i class="fa-regular fa-circle-user fa-lg ml-1 mr-2"></i>
          <span class="truncate inline-block align-middle max-w-[80%]">
            {{ (currentProducer$ | async).CompanyName }}
          </span>
          <i
            [ngClass]="showAccount ? 'fa fa-angle-up' : 'fa fa-angle-down'"
            class="absolute right-2 top-1/2 transform -translate-y-1/2 text-sm"
          >
          </i>
        </span>
      </nav>

      <!-- Dropdown Menu for Producer details -->
      <div
        *ngIf="showAccount"
        class="absolute top-[calc(100%-5px)] left-0 w-full z-30 bg-white text-black rounded-lg shadow-lg"
      >
        <ul class="list-none p-0">
          <li
            class="cursor-pointer hover:bg-zinc-300 rounded p-2 text-base"
            (click)="viewDetails('producer-details')"
          >
            <i class="fa fa-user mr-2"></i> Producer details
          </li>
          <li
            class="cursor-pointer hover:bg-zinc-300 rounded p-2 text-base"
            (click)="viewDetails('account-details')"
          >
            <i class="fa fa-user-gear mr-1"></i> Account settings
          </li>
        </ul>
      </div>
      <hr class="w-full mt-1 mb-1 border-t border-white/20" />
    </div>

    <!--Products section-->
    <div class="flex-1 flex flex-col justify-start py-4 mx-4">
      <nav class="relative">
        <span
          class="block py-2 rounded-lg text-gray cursor-pointer"
          (click)="toggleProducts()"
          [class.bg-zinc-700]="showProducts"
        >
          <i class="fa-solid fa-list ml-1 mr-2"></i>

          <span class="ml-1 flex-grow"> My products </span>
          <i
            [ngClass]="showProducts ? 'fa fa-angle-up' : 'fa fa-angle-down'"
            class="absolute right-2 top-1/2 transform -translate-y-1/2 text-sm"
          >
          </i>
        </span>
      </nav>
      <div *ngIf="showProducts">
        <ul
          class="list-disc pl-6 mt-1.5 max-h-[55vh] md:max-h-[65vh] custom-scrollbar"
        >
          <li
            *ngFor="let product of productsByProducer$ | async"
            class="p-1.5 cursor-pointer rounded hover:bg-zinc-700 text-gray"
            (click)="viewDetails('products', product.Id)"
            [class.bg-zinc-700]="product.Id === activeProductId"
          >
            {{ product.Name }}
          </li>
        </ul>
      </div>
    </div>
    <div class="mx-3">
      <span class="block py-2 rounded text-gray cursor-pointer">
        <nav
          class="cursor-pointer hover:bg-zinc-700 rounded p-2"
          (click)="logout()"
        >
          <i class="fa fa-sign-out mr-4"></i>Log out
        </nav>
      </span>
    </div>
  </div>
  <!-- Main Content Area -->
  <div class="flex-1 p-5 sm:p-5 md:p-5 lg:p-5 pt-20 h-screen overflow-y-scroll">
    <router-outlet></router-outlet>
  </div>
</div>
} @else{
<div class="overflow-y-auto overflow-x-hidden">
  <router-outlet></router-outlet>
</div>
}
