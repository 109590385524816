import { Action } from "@ngrx/store";

export enum ProducerActionTypes {
  CreateProducer = "[Producer] Create Producer",
  CreateProducerSuccess = "[Producer] Create Producer Success",
  CreateProducerFailed = "[Producer] Create Producer Failed",

  UpdateProducer = "[Producer] Update Producer",
  UpdateProducerSuccess = "[Producer] Update Producer Success",
  UpdateProducerFailed = "[Producer] Update Producer Failed",

  UpdateSuccessMessage = "[Producer] Update Success Message",
  UpdateErrorMessage = "[Producer] Update Error Message",

  UpdateCurrentProducer = "[Producer] Update Current Producer",

  DeleteProducerImagesInS3 = "[Producer] Delete Producer Images In S3",
  DeleteProducerImagesInS3Success = "[Producer] Delete Producer Images In S3 Success",
  DeleteProducerImagesInS3Failed = "[Producer] Delete Producer Images In S3 Failed",

  GetProducerByEmail = "[Producer] Get Producer By Email",
  GetProducerByEmailSuccess = "[Producer] Get Producer By Email Success",
  GetProducerByEmailFailed = "[Producer] Get Producer By Email Failed",

  UpdateProducerFromWebsocket = "[Producer] Update Producer From Websocket",
}

export class CreateProducer implements Action {
  readonly type = ProducerActionTypes.CreateProducer;

  constructor(public payload: any) {}
}

export class CreateProducerSuccess implements Action {
  readonly type = ProducerActionTypes.CreateProducerSuccess;

  constructor(public payload: any) {}
}

export class CreateProducerFailed implements Action {
  readonly type = ProducerActionTypes.CreateProducerFailed;

  constructor(public payload: any) {}
}

export class UpdateProducer implements Action {
  readonly type = ProducerActionTypes.UpdateProducer;

  constructor(public payload: any, public generateContent?: boolean) {}
}

export class UpdateProducerSuccess implements Action {
  readonly type = ProducerActionTypes.UpdateProducerSuccess;

  constructor(public payload: any) {}
}

export class UpdateProducerFailed implements Action {
  readonly type = ProducerActionTypes.UpdateProducerFailed;

  constructor(public payload: any) {}
}

export class UpdateErrorMessage implements Action {
  readonly type = ProducerActionTypes.UpdateErrorMessage;

  constructor(public payload: any) {}
}

export class UpdateSuccessMessage implements Action {
  readonly type = ProducerActionTypes.UpdateSuccessMessage;

  constructor(public payload: any) {}
}

export class UpdateCurrentProducer implements Action {
  readonly type = ProducerActionTypes.UpdateCurrentProducer;

  constructor(public payload: any) {}
}

export class GetProducerByEmail implements Action {
  readonly type = ProducerActionTypes.GetProducerByEmail;

  constructor(public payload: any) {}
}

export class GetProducerByEmailSuccess implements Action {
  readonly type = ProducerActionTypes.GetProducerByEmailSuccess;

  constructor(public payload: any) {}
}

export class GetProducerByEmailFailed implements Action {
  readonly type = ProducerActionTypes.GetProducerByEmailFailed;

  constructor(public payload: any) {}
}

export class UpdateProducerFromWebsocket implements Action {
  readonly type = ProducerActionTypes.UpdateProducerFromWebsocket;

  constructor(public payload: any) {}
}

export class DeleteProducerImagesInS3 implements Action {
  readonly type = ProducerActionTypes.DeleteProducerImagesInS3;

  constructor(public payload: any) {}
}

export class DeleteProducerImagesInS3Success implements Action {
  readonly type = ProducerActionTypes.DeleteProducerImagesInS3Success;
  constructor(public payload: any) {}
}

export class DeleteProducerImagesInS3Failed implements Action {
  readonly type = ProducerActionTypes.DeleteProducerImagesInS3Failed;
  constructor(public payload: any) {}
}

export type ProducerActions =
  | CreateProducer
  | CreateProducerSuccess
  | CreateProducerFailed
  | UpdateProducer
  | UpdateProducerSuccess
  | UpdateProducerFailed
  | UpdateSuccessMessage
  | UpdateErrorMessage
  | UpdateCurrentProducer
  | GetProducerByEmail
  | GetProducerByEmailSuccess
  | GetProducerByEmailFailed
  | UpdateProducerFromWebsocket
  | DeleteProducerImagesInS3
  | DeleteProducerImagesInS3Success
  | DeleteProducerImagesInS3Failed;
