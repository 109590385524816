import { FormGroup } from "@angular/forms";

export class GenericValidator {
  /**
   * Provide the set of valid validation messages
   * Structure:
   * controlName1: {
   *      validationRuleName1: 'Validation Messages',
   *      validationRuleName2: 'Validation Message'
   * }
   * controlName2: {
   *      validationRuleName1: 'Validation Messages',
   *      validationRuleName2: 'Validation Message'
   * }
   */
  constructor(
    private validationMessages: { [key: string]: { [key: string]: string } }
  ) {}

  /**
   * Process each control withing a FormGroup
   * And returns a set of validation messages to display
   *
   * Structure
   * controlName1: 'Validation Message',
   * controlName2: 'Validation Message'
   */
  processMessages(container: FormGroup): { [key: string]: string } {
    const messages: { [key: string]: any } = {};

    for (const controlKey in container.controls) {
      if (container.controls.hasOwnProperty(controlKey)) {
        const c = container.controls[controlKey];
        // If it is a FormGroup, process its child controls.
        if (c instanceof FormGroup) {
          const childMessages = this.processMessages(c);
          Object.assign(messages, childMessages);
        } else {
          // Only validate if there are validation messages for the control
          if (this.validationMessages[controlKey]) {
            messages[controlKey] = "";
            if (c.errors) {
              Object.keys(c.errors).map((messageKey) => {
                if (this.validationMessages[controlKey][messageKey]) {
                  messages[controlKey] +=
                    this.validationMessages[controlKey][messageKey] + " ";
                }
              });
            }
          }
        }
      }
    }
    return messages;
  }
}
