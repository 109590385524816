import { Component } from "@angular/core";

@Component({
  selector: "app-dialog-confirmation",
  templateUrl: "./dialog-confirmation.component.html",
})
export class DialogConfirmationComponent {
  title: string;
  message: string;
}
