import { Injectable } from "@angular/core";
import {
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivateFn,
} from "@angular/router";
import { CognitoService } from "@app/services/cognito/cognito.service";
import { inject } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class RedirectGuard {
  constructor(private authService: CognitoService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    return this.redirect();
  }

  private async redirect(): Promise<boolean> {
    const isAuth = await this.authService.isLoggedIn();
    if (isAuth) {
      this.router.navigate(["products"]);
      return false;
    }
    return true;
  }
}

export const RedirectGuardFn: CanActivateFn = (route, state) => {
  return inject(RedirectGuard).canActivate(route, state);
};
