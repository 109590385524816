<div class="relative flex items-center justify-between pl-5">
  <h2 class="text-left inline p-0 m-0">{{ title }}</h2>
  <button
    mat-icon-button
    mat-dialog-close
    tabindex="-1"
    class="absolute right-0 top-0 m-2 cursor-pointer"
  >
    <i class="fa fa-close text-gray"></i>
  </button>
</div>
<div class="pt-0 p-5 flex items-center justify-center">
  <p class="text-center">{{ message }}</p>
</div>

<div class="flex flex-col md:flex-row justify-end p-5">
  <button
    type="button"
    class="border border-solid border-gray rounded bg-white py-2 px-3 md:px-4 lg:px-5 cursor-pointer text-zinc-500 mb-2 md:mb-0 md:mr-2 lg:mr-4"
    mat-dialog-close
  >
    No
  </button>
  <button
    type="button"
    class="border border-solid border-primary rounded bg-primary py-2 px-3 md:px-4 lg:px-5 cursor-pointer text-white"
    [mat-dialog-close]="true"
  >
    Yes
  </button>
</div>
