@if(currentProducer && !currentProducer.UpdatedAt &&
!currentProducer.GeneratedImages && !currentProducer.SocialMediaContents){
<div class="h-full w-full p-5 sm:p-6 lg:p-8">
  <div class="flex items-center justify-center">
    <form
      [formGroup]="updateProducerForm"
      (ngSubmit)="updateProducer()"
      class="w-full max-w-[600px]"
    >
      <h2 class="mt-0 mb-6 font-medium text-center">
        Skapa logotyp och social-mediatext för din verksamhet / Generate content
        for your producer
      </h2>
      <div class="mb-6">
        <label class="font-medium block text-sm mb-1" for="Name"
          >Namn på logotypen / Name on logotype</label
        >
        <input
          class="border border-solid rounded-lg w-full py-3 px-2 leading-tight placeholder-gray"
          type="text"
          autocomplete="on"
          id="Name"
          formControlName="Name"
          placeholder="Enter the name of the producer"
          (keyup)="validateInput()"
          [ngClass]="
            displayMessage['Name']
              ? 'border-danger outline-danger'
              : 'border-gray outline-zinc-600'
          "
          required
        />
        <span
          NgIf="displayMessage.Name"
          class="text-danger w-[29rem] text-sm block"
        >
          {{ displayMessage["Name"] }}
        </span>
      </div>

      <div class="mb-5">
        <label class="font-medium block text-sm mb-1"
          >How would you describe your brand?</label
        >
        <div *ngFor="let tag of producerTags" class="w-[29.5rem] inline">
          <button
            type="button"
            class="py-2 mb-2.5 mr-2.5 rounded-lg text-sm border border-solid bg-white text-normal font-bold text-center cursor-pointer"
            (click)="tag.active = !tag.active"
            [ngClass]="tag.active ? 'outline outline-zinc-600' : 'border-gray'"
          >
            {{ tag.name }}
          </button>
        </div>
      </div>

      <div class="mb-6">
        <label class="font-medium block text-sm mb-2" for="ShortDescription"
          >Kort beskrivning av verksamheten (ej nödvändig) / Short Description
          (optional)</label
        >
        <textarea
          class="border border-solid rounded-lg w-full py-2 px-2 leading-tight font-sans border-gray outline-zinc-600 placeholder-gray"
          rows="7"
          placeholder="Describe your producer"
          id="ShortDescription"
          formControlName="ShortDescription"
        ></textarea>
      </div>

      <div class="mb-6">
        <div class="flex flex-row justify-between w-full">
          <label class="font-medium text-sm mb-1" for="Photo"
            >Photo (optional)</label
          >
          <span
            *ngIf="producerPhoto"
            (click)="removePhoto()"
            class="text-primary text-sm cursor-pointer"
            >Remove photo</span
          >
        </div>
        <div
          class="w-full h-32 rounded-lg border border-solid border-gray"
          onclick="document.getElementById('Photo').click()"
        >
          <img
            [src]=""
            id="photo-preview"
            class="w-full object-cover"
            [ngClass]="producerPhoto ? 'h-32' : ''"
            alt=""
          />
          <div
            *ngIf="!producerPhoto"
            class="flex flex-col items-center justify-center p-7"
          >
            <i class="fa fa-image text-gray text-lg"></i>
            <span class="text-primary text-sm cursor-pointer"
              >Select a file</span
            >
          </div>
          <input
            type="file"
            #fileUpload
            accept="image/*"
            id="Photo"
            name="photo"
            class="hidden"
            (change)="onPhotoSelected($event)"
          />
        </div>
      </div>

      <div class="mb-6">
        <label class="font-medium block text-sm mb-2" for="Implementation"
          >Select implementation</label
        >
        <div class="flex flex-center flex-col sm:flex-row gap-4">
          <div *ngFor="let impl of implementations" class="w-full">
            <button
              type="button"
              id="Implementation"
              class="w-full py-2 mb-2.5 rounded-lg text-sm border border-solid bg-white text-normal text-gray text-center cursor-pointer"
              (click)="selectedImpl = impl"
              [ngClass]="
                selectedImpl == impl
                  ? 'outline outline-zinc-600'
                  : 'border-gray'
              "
            >
              {{ impl }}
            </button>
          </div>
        </div>
      </div>

      <div>
        <button
          class="border border-solid block w-full rounded-lg bg-primary py-3 px-3 mb-2 cursor-pointer text-white"
          type="submit"
        >
          Generate
        </button>
      </div>
    </form>
  </div>
</div>
}
