import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateFn,
  Router,
} from "@angular/router";
import { inject } from "@angular/core";
import { Store, select } from "@ngrx/store";
import * as fromRoot from "@app/state";
import * as productActions from "@app/state/product/product.actions";
import * as fromProduct from "@app/state/product";
import { Observable, map, take } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ProductDetailsGuard implements CanActivate {
  constructor(private router: Router, private store: Store<fromRoot.State>) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | boolean {
    const productId = route.params["id"];
    if (productId) {
      return this.store.pipe(
        select(fromProduct.getAllProductsByProducer),
        take(1),
        map((products) => {
          const product = products.find((prod) => prod.Id === productId);
          if (product) {
            this.store.dispatch(
              new productActions.UpdateCurrentProduct(product)
            );
            return true;
          } else {
            this.store.dispatch(new productActions.UpdateCurrentProduct(null));
            this.router.navigate(["products"]);
            return false;
          }
        })
      );
    } else {
      this.store.dispatch(new productActions.UpdateCurrentProduct(null));
      return false;
    }
  }
}

export const ProductDetailsGuardFn: CanActivateFn = (route) => {
  return inject(ProductDetailsGuard).canActivate(route);
};
