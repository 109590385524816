@if(currentProducer){
<span
  (click)="navigateToHomePage()"
  class="text-primary text-md cursor-pointer mb-4 block"
>
  <i class="fa fa-chevron-left"></i>
  Back
</span>

<div
  class="flex items-center sm:flex-row md:flex-row lg:flex-row product-details"
>
  <div class="w-1/7 mr-3 mb-2">
    <div
      class="relative group h-[10.9rem]"
      (click)="openDialogForChoosingPhoto()"
    >
      <ng-container *ngIf="currentProducer.Photo; else noImage">
        <img
          class="bg-gray-200 bg-cover bg-center rounded-md w-[11rem] h-[11rem] lg:w-[11rem] lg:h-[11rem] md:w-[11rem] md:h-[11rem] sm:w-[11rem] sm:h-[11rem]"
          alt="Producer photo"
          [src]="currentProducer.Photo"
        />
      </ng-container>

      <ng-template #noImage>
        <div
          class="bg-[#f2f2f2] rounded-md flex items-center justify-center w-[11rem] h-[11rem] lg:h-[11rem] md:w-[11rem] md:h-[11rem] sm:w-[11rem] sm:h-[11rem]"
        >
          No photo
        </div>
      </ng-template>
      <div
        class="absolute rounded inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 hover:cursor-pointer hover:bg-black hover:bg-opacity-20"
      >
        <div class="relative group">
          <i class="fas fa-camera text-white text-xl"></i>
          <span class="tooltiptext"> Change photo </span>
        </div>
      </div>
    </div>
  </div>
  <div class="w-6/7">
    <h1 class="text-lg font-bold mb-0 mt-0">
      {{ currentProducer.CompanyName }} -
      {{ currentProducer.OrganizationNumber }}
    </h1>
    <p class="text-sm text-gray mb-2 whitespace-normal mt-0">
      {{ currentProducer.Tags }}
    </p>
    <p class="text-sm text-gray mb-2 whitespace-normal mt-0">
      {{ currentProducer.ShortDescription }}
    </p>
  </div>
</div>

<p
  *ngIf="
    currentProducer.SocialMediaContents &&
    currentProducer.SocialMediaContents.length == 0
  "
  class="flex justify-center justify-items-center"
>
  <mat-spinner [diameter]="40" color="warn"></mat-spinner> <br />
  &nbsp; The social media content is being generated...
</p>

<div class="grid sm:grid-cols-2 gap-6 mt-6">
  @for (socialMedia of currentProducer.SocialMediaContents;track
  socialMedia.Platform) {
  <div
    class="flex flex-col bg-white rounded-lg overflow-hidden shadow-md border-1 border-[#dadada] border-solid"
  >
    <div class="m-4">
      <h5 class="text-lg font-semibold mt-0 mb-0 text-gray-900">
        {{ socialMedia.Platform }}
      </h5>
    </div>
    <div class="m-4">
      @if(socialMedia.Platform!=='Twitter') {
      <span class="block text-sm text-gray">Title:</span>
      <b class="text-sm">{{ socialMedia.Content.Title }}</b>
      } @else{
      <span class="block text-sm text-gray">Tweets:</span>
      }
    </div>
    <div class="m-4">
      <ng-container
        *ngIf="socialMedia.Platform === 'Twitter'; else otherPlatform"
      >
        <div *ngFor="let tweet of socialMedia.Content.Description.split('\n')">
          <p class="text-sm whitespace-pre-line mt-0">{{ tweet }}</p>
        </div>
      </ng-container>
      <ng-template #otherPlatform>
        <span class="block text-sm text-gray">Description:</span>
        <span class="text-sm whitespace-pre-line mt-0">{{
          socialMedia.Content.Description
        }}</span>
      </ng-template>
    </div>
    <div class="p-2 text-right mt-auto">
      <span
        class="text-gray cursor-pointer"
        (click)="copyContent(socialMedia)"
        title="Copy to clipboard"
      >
        <i class="fa-solid fa-copy"></i>
      </span>
    </div>
  </div>
  }
</div>

<div
  *ngIf="
    currentProducer.GeneratedImages && currentProducer.GeneratedImages.length
  "
  class="bg-white rounded-lg overflow-hidden shadow-md border-1 border-[#dadada] border-solid mt-6"
>
  <div class="m-4">
    <h5 class="text-lg font-semibold mt-0 mb-0 text-gray-900">Photos</h5>
  </div>
  <div class="grid grid-cols-1 sm:grid-cols-2 gap-6 m-4">
    <ng-container *ngFor="let image of currentProducer.GeneratedImages">
      <img
        *ngIf="image !== ''"
        src="{{ image }}"
        class="w-full aspect-square"
        alt="Image"
      />
    </ng-container>
  </div>
</div>

<p
  *ngIf="
    currentProducer.GeneratedImages &&
    currentProducer.GeneratedImages.length != 12
  "
  class="flex justify-center justify-items-center"
>
  <mat-spinner [diameter]="40" color="warn"></mat-spinner> <br />
  &nbsp; Images are being generated...
</p>

<div class="buttons-div mt-6 pb-5 flex justify-end">
  <button
    type="button"
    class="w-60 border border-solid border-primary rounded-lg bg-white py-4 md:px-6 cursor-pointer text-primary outline-primary mr-4"
    (click)="navigateToHomePage()"
  >
    <i class="fa fa-chevron-left"></i>
    Back to home page
  </button>
  <button
    type="button"
    class="w-60 border border-solid border-primary rounded-lg bg-white py-4 md:px-6 cursor-pointer text-primary outline-primary mr-4"
    (click)="regenerateProducerContent()"
  >
    Regenerate
  </button>
</div>
}
