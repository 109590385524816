<main class="landing">
  <div class="content">
    <img src="../../../../assets/images/logo.png" alt="" class="logo" />
    <h2 class="mb-6 mt-0 text-4xl font-medium">Welcome back</h2>
    <form [formGroup]="userForm" (ngSubmit)="login()">
      <div class="mb-6">
        <label class="font-medium block text-sm mb-1" for="Email">Email</label>
        <input
          class="border border-solid rounded-lg w-full p-2 leading-tight placeholder-gray h-10"
          type="text"
          id="Email"
          formControlName="Email"
          placeholder="Enter your email"
          (keyup)="validateInput()"
          [ngClass]="
            displayMessage.Email
              ? 'border-danger outline-danger'
              : 'border-gray outline-zinc-600'
          "
          required
        />
        <span
          NgIf="displayMessage.Email"
          class="text-danger w-full text-sm block"
        >
          {{ displayMessage.Email }}
        </span>
      </div>
      <div class="mb-5">
        <label class="block font-medium text-sm mb-1" for="Password"
          >Password</label
        >
        <div>
          <input
            class="border border-solid rounded-lg w-full p-2 leading-tight placeholder-gray h-10"
            [type]="showPassword ? 'text' : 'password'"
            id="Password"
            formControlName="Password"
            placeholder="Enter a password"
            (keyup)="validateInput()"
            required
            [ngClass]="
              displayMessage.Password
                ? 'border-danger outline-danger'
                : 'border-gray outline-zinc-600'
            "
          />
          <span
            (click)="showHidePassword()"
            class="relative -ml-6 cursor-pointer"
          >
            <i [ngClass]="!showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'"></i>
          </span>
          <span
            NgIf="displayMessage.Password"
            class="text-danger w-full text-sm block"
          >
            {{ displayMessage.Password }}
          </span>
          <span
            (click)="navigateToForgotPassword()"
            class="cursor-pointer text-primary text-sm"
            >Forgot your password?</span
          >
        </div>
      </div>
      <div>
        <button
          class="border border-none block w-full rounded-lg bg-primary p-2 px-3 mb-2.5 cursor-pointer text-white h-9"
          type="submit"
        >
          Log in
        </button>
        <span class="text-lg text-zinc-500" for=""
          >Don't have an account?
          <span
            (click)="navigateToRegister()"
            class="text-primary cursor-pointer"
            >Register</span
          >
        </span>
      </div>
    </form>
    <app-introduction></app-introduction>
  </div>
  <aside class="promo-image"></aside>
</main>
