<div class="relative flex items-center justify-between pl-5">
  <h2 class="text-left inline p-0 m-0">{{ dialogTitle }}</h2>
  <button
    mat-icon-button
    mat-dialog-close
    tabindex="-1"
    class="absolute right-0 top-0 m-2 cursor-pointer"
  >
    <i class="fa fa-close text-gray"></i>
  </button>
</div>
@if(!chosenPhoto){
<div class="pt-0 pl-5 pb-5">
  <button
    type="button"
    onclick="document.getElementById('Photo').click()"
    class="w-48 border border-solid border-primary rounded-lg bg-white py-4 md:px-6 cursor-pointer text-primary outline-primary"
  >
    <i class="fa fa-plus"></i>
    Upload photo
  </button>
  <input
    type="file"
    #fileUpload
    accept="image/*"
    id="Photo"
    name="photo"
    class="hidden"
    (change)="onPhotoUpload($event)"
  />
</div>
@if(currentItem && currentItem.GeneratedImages &&
currentItem.GeneratedImages.length){
<div class="pt-0 pl-5 pb-5">
  <h3 class="text-left mt-0">Suggested photos</h3>
  <div
    class="grid grid-cols-6 xl:grid-cols-6 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 gap-3 pr-5 images-list overflow-auto max-h-[60vh]"
  >
    <ng-container *ngFor="let image of currentItem.GeneratedImages">
      <img
        *ngIf="image !== ''"
        src="{{ image }}"
        class="w-full aspect-square rounded hover:cursor-pointer"
        alt="Photo"
        (click)="chosenPhoto = image; photoUrl = image"
      />
    </ng-container>
  </div>
</div>
} } @else{
<div class="pt-0 p-5 flex items-center justify-center">
  <img
    class="object-contain max-w-full max-h-72 w-72 h-72 aspect-square rounded"
    [src]="photoUrl"
  />
</div>
<div class="flex flex-col md:flex-row justify-end p-5">
  <button
    type="button"
    class="border border-solid border-gray rounded bg-white py-2 px-3 md:px-4 lg:px-5 cursor-pointer text-zinc-500 mb-2 md:mb-0 md:mr-2 lg:mr-4"
    mat-dialog-close
  >
    Cancel
  </button>
  <button
    type="button"
    class="border border-solid border-primary rounded bg-primary py-2 px-3 md:px-4 lg:px-5 cursor-pointer text-white"
    (click)="saveChosenPhoto()"
  >
    Select
  </button>
</div>
}
