<main class="landing">
  <div class="content">
    <img src="../../../../assets/images/logo.png" alt="" class="logo" />
    @if(!showVerifyMessage){
    <h2 class="mb-6 mt-0 text-4xl font-medium">Create your account</h2>
    <form [formGroup]="userForm" (ngSubmit)="register()">
      <div class="mb-6">
        <label class="font-medium block text-sm mb-1" for="Email">Email</label>
        <input
          class="border border-solid rounded-lg w-full p-2 leading-tight placeholder-gray h-10"
          type="text"
          id="Email"
          required
          formControlName="Email"
          placeholder="Enter your email"
          autocomplete="on"
          (keyup)="validateInput()"
          [ngClass]="
            displayMessage.Email
              ? 'border-danger outline-danger'
              : 'border-gray outline-zinc-600'
          "
        />
        <span
          NgIf="displayMessage.Email"
          class="text-danger w-full text-sm block"
        >
          {{ displayMessage.Email }}
        </span>
      </div>
      <div class="mb-6">
        <label class="block font-medium text-sm mb-1" for="Password"
          >Password</label
        >
        <div>
          <input
            class="border border-solid rounded-lg w-full p-2 leading-tight placeholder-gray h-10"
            [type]="showPassword ? 'text' : 'password'"
            id="Password"
            formControlName="Password"
            placeholder="Enter a password"
            (keyup)="validateInput()"
            required
            [ngClass]="
              displayMessage.Password
                ? 'border-danger outline-danger'
                : 'border-gray outline-zinc-600'
            "
          />
          <span
            (click)="showPassword = !showPassword"
            class="relative -ml-6 cursor-pointer"
          >
            <i [ngClass]="!showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'"></i>
          </span>
          <span
            NgIf="displayMessage.Password"
            class="text-danger w-full text-sm block"
          >
            {{ displayMessage.Password }}
          </span>
        </div>
      </div>
      <div class="mb-6">
        <label class="block font-medium text-sm mb-1" for="ConfirmPassword"
          >Confirm password</label
        >
        <div>
          <input
            class="border border-solid rounded-lg w-full p-2 leading-tight placeholder-gray h-10"
            [type]="showConfirmPassword ? 'text' : 'password'"
            id="ConfirmPassword"
            formControlName="ConfirmPassword"
            placeholder="Repeat your password"
            (keyup)="validateInput()"
            [ngClass]="
              displayMessage.ConfirmPassword
                ? 'border-danger outline-danger'
                : 'border-gray outline-zinc-600'
            "
            required
          />
          <span
            (click)="showConfirmPassword = !showConfirmPassword"
            class="relative -ml-6 cursor-pointer"
          >
            <i
              [ngClass]="!showConfirmPassword ? 'fa fa-eye' : 'fa fa-eye-slash'"
            ></i>
          </span>
          <span
            NgIf="displayMessage.ConfirmPassword"
            class="text-danger w-full text-sm block"
          >
            {{ displayMessage.ConfirmPassword }}
          </span>
        </div>
      </div>
      <div>
        <button
          class="border border-none block w-full rounded-lg bg-primary p-2 px-3 mb-2.5 cursor-pointer text-white h-9"
          type="submit"
        >
          Create account
        </button>
        <span class="text-lg text-zinc-500"
          >Already have an account?
          <span (click)="navigateToLogin()" class="text-primary cursor-pointer"
            >Log in</span
          >
        </span>
      </div>
    </form>
    } @else{
    <div class="w-[70%] text-center">
      <h2 class="mb-6 mt-0 font-medium">
        Successful register! <br />
        We have sent you a verification email.
      </h2>
      <h3 class="mb-6 mt-0 font-medium">
        Please, verify your email in order to continue!
      </h3>
      <button
        type="button"
        class="border border-none w-full block rounded-lg bg-primary p-2 px-3 mb-2.5 cursor-pointer text-white h-9"
        (click)="continueToUpdateProducer()"
      >
        I have verified my email
        <i class="fa-solid fa-arrow-right text-white"></i>
      </button>
    </div>
    }
    <app-introduction></app-introduction>
  </div>
  <aside class="promo-image"></aside>
</main>
