import { Injectable } from "@angular/core";
import {
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivateFn,
} from "@angular/router";
import { CognitoService } from "@app/services/cognito/cognito.service";
import { inject } from "@angular/core";
import { Store } from "@ngrx/store";
import * as fromRoot from "@app/state";
import * as producerActions from "@app/state/producer/producer.actions";
import * as productActions from "@app/state/product/product.actions";
import { WebsocketService } from "@app/services/websocket/web-socket.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuard {
  constructor(
    private authService: CognitoService,
    private websocketService: WebsocketService,
    private router: Router,
    private store: Store<fromRoot.State>
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    return this.checkAuth();
  }

  private async checkAuth(): Promise<boolean> {
    let isAuth = await this.authService.isLoggedIn();
    if (!isAuth) {
      this.store.dispatch(new producerActions.UpdateCurrentProducer(null));
      this.store.dispatch(new productActions.UpdateCurrentProduct(null));
      this.store.dispatch(new productActions.EmptyProductsByProducer());
      this.websocketService.closeWebsocketConnection();
      this.router.navigate(["login"]);
    } else {
      const userSession = await this.authService.getUserSession();
      const email =
        userSession &&
        userSession.getIdToken() &&
        userSession.getIdToken().payload["email"]
          ? userSession.getIdToken().payload["email"]
          : null;
      if (email) {
        this.store.dispatch(new producerActions.GetProducerByEmail(email));
      }
    }
    return isAuth;
  }
}

export const AuthGuardFn: CanActivateFn = (route, state) => {
  return inject(AuthGuard).canActivate(route, state);
};
