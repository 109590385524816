<div class="flex flex-col items-center justify-start p-1">
  <div class="w-full max-w-3xl text-left">
    <p class="text-[32px] font-semibold leading-[48px]">
      Right to Erasure (Account Deletion)
    </p>
    <p class="text-[16px] font-normal leading-[24px] text-left text-zinc-500">
      Under the General Data Protection Regulation (GDPR), you have the right to
      request the deletion of your personal data, also known as the "right to be
      forgotten." If you would like to delete your account and remove all
      associated personal information from our systems, you can submit a request
      to us.
    </p>
    <p class="text-[16px] font-normal leading-[24px] text-left text-zinc-500">
      To exercise your right to erasure, please send an email to: <br />
      <a href="mailto:team@agrovia.ai" class="no-underline text-primary">
        {{ "team@agrovia.ai" }}
      </a>
    </p>
  </div>
</div>
