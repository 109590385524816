import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { IDeleteImagesInS3 } from "@app/models/shared.model";
import { environment } from "@environments/environment";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class LambdaService {
  constructor(private http: HttpClient) {}

  public getCompanyInfo(companyId: string): Observable<any> {
    const apiUrl = environment.ApiUrl + "/get-company-info";
    return this.http.get<any>(apiUrl, {
      params: {
        companyId: companyId,
      },
    });
  }

  public generateSocialMediaContent<T>(input: T): Observable<any> {
    const apiUrl = environment.ApiUrl + "/generate-social-media-content";
    return this.http.post<any>(apiUrl, input);
  }

  public generateImages<T>(input: T): Observable<any> {
    const apiUrl = environment.ApiUrl + "/generate-images";
    return this.http.post<any>(apiUrl, input);
  }

  public deleteImagesFromS3(input: IDeleteImagesInS3): Observable<any> {
    const apiUrl = environment.ApiUrl + "/delete-images-from-s3";
    return this.http.post<any>(apiUrl, input);
  }
}
