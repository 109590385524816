import { IProduct } from "@app/models/product.model";
import gql from "graphql-tag";

export const PRODUCTS_BY_PRODUCER_QUERY = gql`
  query ProductsByProducer($OrganizationNumber: String!) {
    productsByProducer(OrganizationNumber: $OrganizationNumber) {
      Id
      OrganizationNumber
      Name
      Price
      Unit
      Stock
      Category
      Tags
      Photo
      ShortDescription
      UpdatedAt
      CreatedAt
      GeneratedImages
      Implementation
      SocialMediaContents {
        Platform
        Content {
          Title
          Description
        }
      }
    }
  }
`;

export const CREATE_PRODUCT = gql`
  mutation CreateNewProduct($input: NewProduct!) {
    newProduct(input: $input) {
      id
    }
  }
`;

export const UPDATE_PRODUCT = gql`
  mutation UpdateProduct($input: UpdateProduct!) {
    updateProduct(input: $input)
  }
`;

export const DELETE_PRODUCT = gql`
  mutation DeleteProduct($input: DeleteProduct!) {
    deleteProduct(input: $input)
  }
`;

export interface ResponseProductsByProducer {
  productsByProducer: IProduct[];
}

export interface CreateProductResponse {
  newProduct: {
    id: string;
  };
}

export interface DeleteProductResponse {
  deleteProduct: string;
}

export interface UpdateProductResponse {
  updateProduct: string;
}
