import { createFeatureSelector, createSelector } from "@ngrx/store";
import * as fromProducts from "./product.reducer";

export const getProductsFeatureState =
  createFeatureSelector<fromProducts.ProductsState>("products");

export const selectProductsEntities = createSelector(
  getProductsFeatureState,
  fromProducts.selectEntities
);

export const getAllProductsByProducer = createSelector(
  getProductsFeatureState,
  fromProducts.selectAll
);

export const productsByProducerLoaded = createSelector(
  getProductsFeatureState,
  (state) => state.productsByProducerLoaded
);

export const getSuccessMessage = createSelector(
  getProductsFeatureState,
  (state) => state.success
);

export const getErrorMessage = createSelector(
  getProductsFeatureState,
  (state) => state.error
);

export const getCurrentProduct = createSelector(
  getProductsFeatureState,
  (state) => state.currentProduct
);
