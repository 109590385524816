import { createFeatureSelector, createSelector } from "@ngrx/store";
import * as fromProducers from "./producer.reducer";

export const getProducersFeatureState =
  createFeatureSelector<fromProducers.ProducersState>("producers");

export const selectProducerEntities = createSelector(
  getProducersFeatureState,
  fromProducers.selectEntities
);

export const allProducers = createSelector(
  getProducersFeatureState,
  fromProducers.selectAll
);

export const producerLoaded = createSelector(
  getProducersFeatureState,
  (state) => !!state.currentProducer
);

export const getSuccessMessage = createSelector(
  getProducersFeatureState,
  (state) => state.success
);

export const getErrorMessage = createSelector(
  getProducersFeatureState,
  (state) => state.error
);

export const getCurrentProducer = createSelector(
  getProducersFeatureState,
  (state) => state.currentProducer
);
