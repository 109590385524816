import { Component } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { GenericValidator } from "@app/classes/GenericValidator/GenericValidator.class";
import * as producerActions from "@app/state/producer/producer.actions";
import { Store, select } from "@ngrx/store";
import * as fromRoot from "@app/state/index";
import { S3Service } from "@app/services/s3/s3.service";
import { Subject, filter } from "rxjs";
import * as fromProducer from "@app/state/producer";
import { ProducerTags } from "@app/utils/categories-and-tags";
import { IProducer, IProducerTag } from "@app/models/producer.model";
import { CognitoService } from "@app/services/cognito/cognito.service";
import { convertFileToBase64, getUniqueImageName } from "@app/utils/helpers";
import { ImplementationModel } from "@app/utils/enums";

@Component({
  selector: "app-update-producer",
  templateUrl: "./update-producer.component.html",
})
export class UpdateProducerComponent {
  updateProducerForm: FormGroup;
  producerPhoto: File = null;
  genericValidator: GenericValidator;
  validationMessages: { [key: string]: { [key: string]: string } };
  displayMessage: { [key: string]: string } = {};
  producerTags: IProducerTag[] = [];
  implementations: string[] = [
    ImplementationModel.ChatGPTMidjourney,
    ImplementationModel.AmazonBedrock,
  ];
  selectedImpl: string;
  currentProducer: IProducer;
  private _onDestroy$ = new Subject<void>();

  constructor(
    private store: Store<fromRoot.State>,
    private formBuilder: FormBuilder,
    private router: Router,
    private s3Service: S3Service,
    private cognitoService: CognitoService
  ) {
    this.validationMessages = {
      Name: {
        required: "Name is required",
      },
    };
    this.genericValidator = new GenericValidator(this.validationMessages);
  }

  async ngOnInit(): Promise<void> {
    const userSession = await this.cognitoService.getUserSession();
    const userEmail =
      userSession &&
      userSession.getIdToken() &&
      userSession.getIdToken().payload["email"]
        ? userSession.getIdToken().payload["email"]
        : null;
    if (userEmail) {
      this.store.dispatch(new producerActions.GetProducerByEmail(userEmail));
      this.store
        .pipe(
          select(fromProducer.getCurrentProducer),
          filter((producer) => !!producer)
        )
        .subscribe((producer) => {
          this.currentProducer = producer;
          //if the producer is updated or has some content generated, do not route him to update-producer route
          if (
            producer &&
            (producer.UpdatedAt ||
              producer.GeneratedImages ||
              producer.SocialMediaContents)
          ) {
            this.router.navigate(["/producer-details"]);
          }
        });
    } else {
      this.router.navigate(["login"]);
    }

    this.updateProducerForm = this.formBuilder.group({
      Name: [
        (this.currentProducer && this.currentProducer.CompanyName) || "",
        [Validators.required],
      ],
      ShortDescription: [""],
      Tags: [""],
    });
    this.getProducerTags();
    this.selectedImpl = this.implementations[0];
  }
  public getProducerTags(): any {
    this.producerTags = ProducerTags.sort((a, b) => {
      return a > b ? 1 : a < b ? -1 : 0;
    }).map((tag) => {
      return {
        name: tag,
        active: false,
      };
    });
  }

  public validateInput(): void {
    this.displayMessage = this.genericValidator.processMessages(
      this.updateProducerForm
    );
  }

  public onPhotoSelected(event): void {
    if (event && event.target && event.target.files && event.target.files[0]) {
      this.producerPhoto = event.target.files[0];
      var photo_preview = document.getElementById("photo-preview");
      photo_preview["src"] = URL.createObjectURL(event.target.files[0]);
      event.target.value = "";
    }
  }

  public removePhoto(): void {
    this.producerPhoto = null;
    var photo_preview = document.getElementById("photo-preview");
    photo_preview["src"] = "";
  }

  async updateProducer(): Promise<void> {
    this.validateInput();
    if (this.updateProducerForm.valid) {
      let selectedPhoto = null;
      if (this.producerPhoto && this.producerPhoto instanceof File) {
        const base64String = await convertFileToBase64(this.producerPhoto);
        //set the image name to be timestamp.[jpg,jpeg,png,etc depending of the chosen image]
        const imageName = getUniqueImageName(this.producerPhoto.name);
        selectedPhoto = await this.s3Service
          .imageUpload(
            base64String,
            "Producers",
            this.currentProducer.OrganizationNumber,
            imageName
          )
          .then((image) => {
            return image;
          });
      }
      if (this.currentProducer) {
        const updateProducer = {
          Id: this.currentProducer.Id,
          OrganizationNumber: this.currentProducer.OrganizationNumber,
          CompanyName: this.updateProducerForm.value.Name,
          ShortDescription:
            this.updateProducerForm.value.ShortDescription || null,
          Photo: selectedPhoto,
          SocialMediaContents: [],
          GeneratedImages: [],
          Tags: this.producerTags.reduce((filtered, tag) => {
            if (tag.active) {
              filtered.push(tag.name);
            }
            return filtered;
          }, []),
          Implementation: this.selectedImpl,
        };
        this.store.dispatch(
          new producerActions.UpdateProducer(updateProducer, true)
        );
        this.router.navigate(["/producer-details"]);
        setTimeout(() => {
          alert(
            `The social media content and images for producer ${updateProducer.CompanyName} are being created in the background. You can find the text and images under your Producer section. We will notify you once they are ready!\n\nNote: If you do not receive 5 Social media texts and 12 images, please click the 'Regenerate' button.`
          );
        }, 1000);
      }
    }
  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
