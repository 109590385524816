import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ForgotPasswordComponent } from "./components/auth/forgot-password/forgot-password.component";
import { ProductsComponent } from "./components/products/products.component";
import { AuthGuard } from "./guards/auth/auth.guard";
import { CognitoService } from "./services/cognito/cognito.service";
import { LoginComponent } from "./components/auth/login/login.component";
import { RegisterComponent } from "./components/auth/register/register.component";
import { NewProductComponent } from "./components/products/new-product/new-product.component";
import { RedirectGuard } from "./guards/redirect/redirect.guard";
import { ProducerDetailsComponent } from "./components/producers/producer-details/producer-details.component";
import { UpdateProducerComponent } from "./components/producers/update-producer/update-producer.component";
import { ProductDetailsComponent } from "./components/products/product-details/product-details.component";
import { ProductDetailsGuard } from "./guards/product-details/product-details.guard";
import { AccountDetailsComponent } from "./components/account-details/account-details.component";

const routes: Routes = [
  {
    path: "",
    redirectTo: "products",
    pathMatch: "full",
  },
  {
    path: "login",
    component: LoginComponent,
    canActivate: [RedirectGuard],
  },
  {
    path: "register",
    component: RegisterComponent,
    canActivate: [RedirectGuard],
  },
  {
    path: "forgot-password",
    component: ForgotPasswordComponent,
    canActivate: [RedirectGuard],
  },
  {
    path: "products",
    component: ProductsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "products/new-product",
    component: NewProductComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "products/:id",
    component: ProductDetailsComponent,
    canActivate: [AuthGuard, ProductDetailsGuard],
  },
  {
    path: "producer-details",
    component: ProducerDetailsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "account-details",
    component: AccountDetailsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "update-producer",
    component: UpdateProducerComponent,
  },
  {
    path: "**",
    redirectTo: "/",
    pathMatch: "full",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [CognitoService],
})
export class AppRoutingModule {}
