import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@environments/environment";
import { lastValueFrom } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class S3Service {
  SERVER_URL: string = `${environment.ApiUrl}/upload-image-to-s3`;
  constructor(private http: HttpClient) {}

  async imageUpload(
    selectedImage: string,
    folderName: string,
    subFolderName: string,
    imageName: string
  ): Promise<string> {
    const body = {
      folderName,
      subFolderName,
      selectedImage,
      imageName,
    };
    try {
      const uploadedImageUrl = await lastValueFrom(
        this.http.post(this.SERVER_URL, body)
      );
      if (uploadedImageUrl && uploadedImageUrl["url"]) {
        return uploadedImageUrl["url"];
      } else {
        return null;
      }
    } catch (err) {
      console.log("error", err);
      return null;
    }
  }
}
