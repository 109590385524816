import { AbstractControl, ValidatorFn, ValidationErrors } from "@angular/forms";

export default class PasswordValidator {
  public strongPasswordRegex: RegExp = /(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

  constructor() {}

  validate(controlName: string, confirmControlName: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const password = control.get(controlName);
      const confirmPassword = control.get(confirmControlName);
      if (
        password &&
        confirmPassword &&
        password.value !== confirmPassword.value
      ) {
        return { PasswordMatch: false };
      }
      return null;
    };
  }
}
