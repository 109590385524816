<main class="landing">
  <div class="content">
    <img src="../../../../assets/images/logo.png" alt="" class="logo" />

    <h2 class="mb-2 mt-0 text-4xl font-medium">Reset your password</h2>
    @if(!showResetPasswordForm){
    <p class="text-sm text-gray mb-6 mt-2">
      We will send you instructions on mail to reset your password.
    </p>
    <form [formGroup]="forgotPasswordForm" (ngSubmit)="forgotPassword()">
      <div class="mb-6">
        <label class="font-medium block text-sm mb-1" for="Email">Email</label>
        <input
          class="border border-solid rounded-lg w-full p-2 leading-tight placeholder-gray h-10"
          type="text"
          id="Email"
          formControlName="Email"
          placeholder="Enter your email"
          (keyup)="validateInput()"
          [ngClass]="
            displayMessage.Email
              ? 'border-danger outline-danger'
              : 'border-gray outline-zinc-600'
          "
          required
        />
        <span
          NgIf="displayMessage.Email"
          class="text-danger w-full text-sm block"
        >
          {{ displayMessage.Email }}
        </span>
      </div>
      <div>
        <button
          class="border border-none block w-full rounded-lg bg-primary p-2 px-3 mb-2.5 cursor-pointer text-white h-9"
          type="submit"
        >
          Continue
        </button>
        <span
          (click)="navigateToLogin()"
          class="text-primary text-sm cursor-pointer"
          >Take me back to Log in</span
        >
      </div>
    </form>
    } @else if(showResetPasswordForm){
    <form [formGroup]="resetPasswordForm" (ngSubmit)="resetPassword()">
      <p class="text-sm text-gray mb-6 mt-2 text-center">
        We have sent a security code to your email. Please enter it bellow.
      </p>
      <div class="mb-6">
        <label class="font-medium block text-sm mb-1" for="Code">Code</label>
        <input
          class="border border-solid rounded-lg w-full p-2 leading-tight placeholder-gray h-10"
          type="text"
          id="Code"
          formControlName="Code"
          placeholder="Enter security code"
          required
          (keyup)="validateInput()"
          [ngClass]="
            displayMessage.Code
              ? 'border-danger outline-danger'
              : 'border-gray outline-zinc-600'
          "
        />
        <span
          NgIf="displayMessage.Code"
          class="text-danger w-full text-sm block"
        >
          {{ displayMessage.Code }}
        </span>
      </div>
      <div class="mb-6">
        <label class="block font-medium text-sm mb-1" for="NewPassword"
          >New password</label
        >
        <div>
          <input
            class="border border-solid rounded-lg w-full p-2 leading-tight placeholder-gray h-10"
            [type]="showPassword ? 'text' : 'password'"
            id="NewPassword"
            formControlName="NewPassword"
            placeholder="Enter a password"
            (keyup)="validateInput()"
            [ngClass]="
              displayMessage.NewPassword
                ? 'border-danger outline-danger'
                : 'border-gray outline-zinc-600'
            "
            required
          />
          <span
            (click)="showPassword = !showPassword"
            class="relative -ml-6 cursor-pointer"
          >
            <i [ngClass]="!showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'"></i>
          </span>
          <span
            NgIf="displayMessage.NewPassword"
            class="text-danger w-full text-sm block"
          >
            {{ displayMessage.NewPassword }}
          </span>
        </div>
      </div>
      <div class="mb-6">
        <label class="block font-medium text-sm mb-2" for="ConfirmNewPassword"
          >Confirm new password</label
        >
        <div>
          <input
            class="border border-solid rounded-lg w-full p-2 leading-tight placeholder-gray h-10"
            [type]="showConfirmPassword ? 'text' : 'password'"
            id="ConfirmNewPassword"
            formControlName="ConfirmNewPassword"
            placeholder="Repeat your new password"
            (keyup)="validateInput()"
            [ngClass]="
              displayMessage.ConfirmNewPassword
                ? 'border-danger outline-danger'
                : 'border-gray outline-zinc-600'
            "
            required
          />
          <span
            (click)="showConfirmPassword = !showConfirmPassword"
            class="relative -ml-6 cursor-pointer"
          >
            <i
              [ngClass]="!showConfirmPassword ? 'fa fa-eye' : 'fa fa-eye-slash'"
            ></i>
          </span>
          <span
            NgIf="displayMessage.ConfirmNewPassword"
            class="text-danger w-full text-sm block"
          >
            {{ displayMessage.ConfirmNewPassword }}
          </span>
        </div>
      </div>
      <div>
        <button
          class="border border-none block w-full h-10 rounded-lg bg-primary py-2 px-3 mb-2.5 cursor-pointer text-white"
          type="submit"
        >
          Reset password
        </button>
        <span
          (click)="navigateToLogin()"
          class="text-primary text-sm cursor-pointer"
          >Take me back to Log in</span
        >
      </div>
    </form>
    }
    <app-introduction></app-introduction>
  </div>
  <aside class="promo-image"></aside>
</main>
