import { IProducer } from "@app/models/producer.model";
import gql from "graphql-tag";

export const CREATE_PRODUCER = gql`
  mutation CreateNewProducer($input: NewProducer!) {
    newProducer(input: $input) {
      id
    }
  }
`;

export const UPDATE_PRODUCER = gql`
  mutation UpdateProducer($input: UpdateProducer!) {
    updateProducer(input: $input)
  }
`;

export const PRODUCER_BY_EMAIL = gql`
  query GetProducerByEmail($Email: String!) {
    getProducerByEmail(Email: $Email) {
      Id
      OrganizationNumber
      Email
      CompanyName
      ShortDescription
      Photo
      Tags
      UpdatedAt
      GeneratedImages
      Implementation
      SocialMediaContents {
        Platform
        Content {
          Title
          Description
        }
      }
    }
  }
`;

export interface CreateProducerResponse {
  newProducer: {
    id: string;
  };
}

export interface UpdateProducerResponse {
  updateProducer: string;
}

export interface ResponseProducerByEmail {
  getProducerByEmail: IProducer;
}
