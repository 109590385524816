import { EntityState, createEntityAdapter } from "@ngrx/entity";
import { IProduct } from "@app/models/product.model";
import { ProductActionTypes, ProductActions } from "./product.actions";

export interface ProductsState extends EntityState<IProduct> {
  error: any;
  success: any;
  currentProduct: any;
  productsByProducerLoaded: boolean;
}

const adapter = createEntityAdapter<IProduct>({
  selectId: (product: IProduct) => product.Id,
});

export const initialState: ProductsState = adapter.getInitialState({
  error: null,
  success: null,
  currentProduct: null,
  productsByProducerLoaded: false,
});

export function ProductReducer(
  state = initialState,
  action: ProductActions
): ProductsState {
  switch (action.type) {
    case ProductActionTypes.GetProductsByProducerSuccess:
      const sortedProducts = [...action.payload].sort((a, b) => {
        if (a.Name.toLowerCase() < b.Name.toLowerCase()) return -1;
        else if (a.Name.toLowerCase() > b.Name.toLowerCase()) return 1;
        else return 0;
      });
      return adapter.addMany(sortedProducts, {
        ...state,
        productsByProducerLoaded: true,
      });

    case ProductActionTypes.GetProductsByProducerFailed:
      return {
        ...state,
        error: action.payload,
        productsByProducerLoaded: false,
      };

    case ProductActionTypes.EmptyProductsByProducer:
      return adapter.removeAll({ ...state, productsByProducerLoaded: false });

    case ProductActionTypes.CreateProductSuccess:
      return adapter.addOne(action.payload, {
        ...state,
        success: "Product Created Successfully!",
      });

    case ProductActionTypes.CreateProductFailed:
      return { ...state, error: action.payload };

    case ProductActionTypes.DeleteProductSuccess:
      return state.entities[action.payload.Id]
        ? adapter.removeOne(action.payload.Id, {
            ...state,
            success: "Product Deleted Successfully!",
          })
        : { ...state };

    case ProductActionTypes.DeleteProductFailed:
      return { ...state, error: action.payload };

    case ProductActionTypes.UpdateSuccessMessage:
      return { ...state, success: action.payload };

    case ProductActionTypes.UpdateErrorMessage:
      return { ...state, error: action.payload };

    case ProductActionTypes.UpdateCurrentProduct:
      return { ...state, currentProduct: action.payload };

    case ProductActionTypes.UpdateProductFromWebsocket:
      const existingItem = state.entities[action.payload.Id];
      if (existingItem) {
        return adapter.upsertOne(
          action.payload,
          state.currentProduct && existingItem.Id === state.currentProduct.Id
            ? {
                ...state,
                currentProduct: { ...state.currentProduct, ...action.payload },
              }
            : state
        );
      } else {
        return { ...state };
      }

    case ProductActionTypes.UpdateProductSuccess:
      return adapter.upsertOne(action.payload, {
        ...state,
        currentProduct: {
          ...state.currentProduct,
          ...action.payload,
        },
      });

    case ProductActionTypes.UpdateProductFailed:
      return { ...state, error: action.payload };

    default:
      return state;
  }
}

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();
