import { Injectable } from "@angular/core";
import { IProduct } from "@app/models/product.model";
import { Apollo } from "apollo-angular";
import { Observable } from "rxjs/index";
import { map } from "rxjs/operators";
import {
  CREATE_PRODUCT,
  CreateProductResponse,
  DELETE_PRODUCT,
  DeleteProductResponse,
  PRODUCTS_BY_PRODUCER_QUERY,
  UpdateProductResponse,
  ResponseProductsByProducer,
  UPDATE_PRODUCT,
} from "./product.graphql.queries";

@Injectable({ providedIn: "root" })
export class ProductGraphQLService {
  constructor(private apollo: Apollo) {}

  getAllByProducer(OrganizationNumber: String): Observable<IProduct[]> {
    return this.apollo
      .query<ResponseProductsByProducer>({
        query: PRODUCTS_BY_PRODUCER_QUERY,
        fetchPolicy: "no-cache",
        variables: { OrganizationNumber },
      })
      .pipe(map((res) => res.data.productsByProducer));
  }

  createOne(input: IProduct): Observable<CreateProductResponse["newProduct"]> {
    return this.apollo
      .mutate<CreateProductResponse>({
        mutation: CREATE_PRODUCT,
        variables: { input: input },
        fetchPolicy: "no-cache",
      })
      .pipe(map((res) => res.data?.newProduct));
  }

  deleteOne(input: any): Observable<string> {
    return this.apollo
      .mutate<DeleteProductResponse>({
        mutation: DELETE_PRODUCT,
        variables: { input },
        fetchPolicy: "no-cache",
      })
      .pipe(map((res) => res.data?.deleteProduct));
  }

  updateOne(input: any): Observable<string> {
    return this.apollo
      .mutate<UpdateProductResponse>({
        mutation: UPDATE_PRODUCT,
        variables: { input },
        fetchPolicy: "no-cache",
      })
      .pipe(map((res) => res.data?.updateProduct));
  }
}
