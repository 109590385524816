import { EntityState, createEntityAdapter } from "@ngrx/entity";
import { ProducerActionTypes, ProducerActions } from "./producer.actions";
import { IProducer } from "@app/models/producer.model";

export interface ProducersState extends EntityState<IProducer> {
  error: any;
  success: any;
  currentProducer: any;
}

const adapter = createEntityAdapter<IProducer>({
  selectId: (producer: IProducer) => producer.Id,
});

export const initialState: ProducersState = adapter.getInitialState({
  error: null,
  success: null,
  currentProducer: null,
});

export function ProducerReducer(
  state = initialState,
  action: ProducerActions
): ProducersState {
  switch (action.type) {
    case ProducerActionTypes.CreateProducerSuccess:
      return adapter.addOne(action.payload, {
        ...state,
        success: "Producer Created Successfully!",
      });

    case ProducerActionTypes.CreateProducerFailed:
      return { ...state, error: action.payload };

    case ProducerActionTypes.UpdateProducerSuccess:
      return adapter.upsertOne(
        action.payload,
        state.currentProducer
          ? {
              ...state,
              currentProducer: { ...state.currentProducer, ...action.payload },
            }
          : state
      );

    case ProducerActionTypes.UpdateProducerFailed:
      return { ...state, error: action.payload };

    case ProducerActionTypes.UpdateSuccessMessage:
      return { ...state, success: action.payload };

    case ProducerActionTypes.UpdateErrorMessage:
      return { ...state, error: action.payload };

    case ProducerActionTypes.UpdateCurrentProducer:
      return {
        ...state,
        currentProducer: action.payload,
      };

    case ProducerActionTypes.GetProducerByEmailSuccess:
      return adapter.addOne(action.payload, {
        ...state,
        currentProducer: action.payload,
        error: null,
      });

    case ProducerActionTypes.GetProducerByEmailFailed:
      return {
        ...state,
        currentProducer: null,
        error: action.payload,
      };

    case ProducerActionTypes.UpdateProducerFromWebsocket:
      const existingItem = state.entities[action.payload.Id];
      if (existingItem) {
        return adapter.upsertOne(
          action.payload,
          state.currentProducer
            ? {
                ...state,
                currentProducer: {
                  ...state.currentProducer,
                  ...action.payload,
                },
              }
            : state
        );
      } else {
        return { ...state };
      }

    default:
      return state;
  }
}

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();
