import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { IProducer } from "@app/models/producer.model";
import { Store, select } from "@ngrx/store";
import { Subject, takeUntil } from "rxjs";
import { Clipboard } from "@angular/cdk/clipboard";
import * as fromProducer from "@app/state/producer";
import * as fromRoot from "@app/state";
import { ISocialMediaContent } from "@app/models/content.model";
import { DialogConfirmationComponent } from "@app/dialogs/dialog-confirmation/dialog-confirmation.component";
import { MatDialog } from "@angular/material/dialog";
import * as producerActions from "@app/state/producer/producer.actions";
import { DeleteMode, GenerationModel } from "@app/utils/enums";
import { environment } from "@environments/environment";
import { DialogChoosePhotoComponent } from "@app/dialogs/dialog-choose-photo/dialog-choose-photo.component";

@Component({
  selector: "app-producer-details",
  templateUrl: "./producer-details.component.html",
})
export class ProducerDetailsComponent {
  currentProducer: IProducer;
  private _onDestroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private store: Store<fromRoot.State>,
    private clipboard: Clipboard,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.store
      .pipe(
        select(fromProducer.getCurrentProducer),
        takeUntil(this._onDestroy$)
      )
      .subscribe((res) => {
        this.currentProducer = res;
      });
  }

  public regenerateProducerContent(): void {
    const dialogRef = this.dialog.open(DialogConfirmationComponent);
    dialogRef.componentInstance.title = "Confirm Regeneration";
    dialogRef.componentInstance.message =
      "If you regenerate this producer, all the current content and images will be replaced. Are you sure you want to go ahead?";
    dialogRef.afterClosed().subscribe((confirmed) => {
      if (confirmed && this.currentProducer) {
        const updateObj = {
          Id: this.currentProducer.Id,
          CompanyName: this.currentProducer.CompanyName,
          Tags: this.currentProducer.Tags,
          ShortDescription: this.currentProducer.ShortDescription,
          Photo: this.currentProducer.Photo,
          OrganizationNumber: this.currentProducer.OrganizationNumber,
          Implementation: this.currentProducer.Implementation,
          GeneratedImages: [],
          SocialMediaContents: [],
        };
        this.store.dispatch(
          new producerActions.UpdateProducer(updateObj, true)
        );

        // Construct event object for deleting images
        const event = {
          FolderName: this.currentProducer.OrganizationNumber,
          DeleteMode: DeleteMode.GeneratedContent, // Specify 'all', 'generatedContent', or 'profileImage'
          Bucket: environment.BucketName,
          Prefix: "Producers/", //TODO make it more dynamic
          Keywords: [GenerationModel.Midjourney, GenerationModel.Bedrock], // Add specific keywords if needed
        };

        // Dispatch action to delete images from S3
        this.store.dispatch(
          new producerActions.DeleteProducerImagesInS3(event)
        );

        setTimeout(() => {
          alert(
            `The social media content and images for "${updateObj.CompanyName}" are being regenerated. We’ll notify you when it’s done!`
          );
        }, 1000);
      }
    });
  }

  public copyContent(socialMedia: ISocialMediaContent): void {
    let copyText =
      "Title: " +
      socialMedia.Content.Title +
      "\n Description: " +
      socialMedia.Content.Description;
    if (socialMedia.Platform == "Twitter") {
      copyText = socialMedia.Content.Description;
    }
    this.clipboard.copy(copyText);
  }

  public navigateToHomePage(): void {
    this.router.navigate(["products"]);
  }

  public openDialogForChoosingPhoto(): void {
    if (this.currentProducer) {
      const dialog = this.dialog.open(DialogChoosePhotoComponent);
      dialog.componentInstance.dialogTitle = "Choose producer photo";
      dialog.componentInstance.currentItem = this.currentProducer;
    }
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
