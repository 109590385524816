import { Injectable } from "@angular/core";
import { Actions, ofType, createEffect } from "@ngrx/effects";
import { from, lastValueFrom, of } from "rxjs";
import {
  catchError,
  filter,
  map,
  mergeMap,
  switchMap,
  withLatestFrom,
} from "rxjs/operators";
import * as producerActions from "./producer.actions";
import { ProducerGraphQLService } from "@app/services/graphql/producer/producer.graphql.service";
import { Store, select } from "@ngrx/store";
import * as fromProducers from "./index";
import { LambdaService } from "@app/services/lambda/lambda.service";
import { CognitoService } from "@app/services/cognito/cognito.service";
import { WebsocketService } from "@app/services/websocket/web-socket.service";
import {
  IProducer,
  IProducerSocialMediaInput,
} from "@app/models/producer.model";

@Injectable()
export class ProducerEffects {
  createProducer$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(producerActions.ProducerActionTypes.CreateProducer),
      map((action: producerActions.CreateProducer) => action),
      switchMap(({ payload }) =>
        this._producerGraphqlService.createOne(payload).pipe(
          map(
            (data) =>
              new producerActions.CreateProducerSuccess({
                ...payload,
                Id: data.id,
              })
          ),
          catchError((err) => of(new producerActions.CreateProducerFailed(err)))
        )
      )
    );
  });

  updateProducer$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(producerActions.ProducerActionTypes.UpdateProducer),
      map((action: producerActions.UpdateProducer) => action),
      switchMap(({ payload, generateContent }) =>
        this._producerGraphqlService.updateOne(payload).pipe(
          map(() => {
            //if the content needs to be generated or re-generated, otherwise update the producer only
            if (generateContent) {
              this.generateContentAndImages(payload);
            }
            return new producerActions.UpdateProducerSuccess(payload);
          }),
          catchError((err) => of(new producerActions.UpdateProducerFailed(err)))
        )
      )
    );
  });

  getProducerByEmail$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(producerActions.ProducerActionTypes.GetProducerByEmail),
      switchMap((action: producerActions.GetProducerByEmail) =>
        of(action).pipe(
          withLatestFrom(this._store.pipe(select(fromProducers.producerLoaded)))
        )
      ),
      filter(([_, loaded]) => !loaded),
      mergeMap(([{ payload }]) =>
        this._producerGraphqlService.getByEmail(payload).pipe(
          map((data) => {
            this._cognitoService.getUserToken("access_token").then((res) => {
              if (res) {
                this._webSocketService.registerWebsocketConnection(
                  data.OrganizationNumber,
                  res
                );
              }
            });
            return new producerActions.GetProducerByEmailSuccess(data);
          }),
          catchError((err) =>
            of(new producerActions.GetProducerByEmailFailed(err))
          )
        )
      )
    );
  });

  deleteProducerImagesInS3$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(producerActions.ProducerActionTypes.DeleteProducerImagesInS3),
      map((action: producerActions.DeleteProducerImagesInS3) => action),
      switchMap(({ payload }) => {
        return from(this._lambdaService.deleteImagesFromS3(payload)).pipe(
          map(() => {
            return new producerActions.DeleteProducerImagesInS3Success({
              ...payload,
            });
          }),
          catchError((err) =>
            of(new producerActions.DeleteProducerImagesInS3Failed(err))
          )
        );
      })
    );
  });

  generateContentAndImages = async (producer: IProducer) => {
    const input: IProducerSocialMediaInput = {
      ProducerId: producer.Id,
      ProducerName: producer.CompanyName,
      ProducerTags: producer.Tags.length ? producer.Tags.join(",") : "",
      ProducerDescription: producer.ShortDescription || "",
      ProducerPhoto: producer.Photo || "",
      ProducerLocation: "Sweden",
      Category: "Producer",
      OrganizationNumber: producer.OrganizationNumber,
      Implementation: producer.Implementation,
    };
    await lastValueFrom(this._lambdaService.generateSocialMediaContent(input));
    await lastValueFrom(this._lambdaService.generateImages(input));
  };

  constructor(
    private _producerGraphqlService: ProducerGraphQLService,
    private _lambdaService: LambdaService,
    private _cognitoService: CognitoService,
    private _webSocketService: WebsocketService,
    private _store: Store<any>,
    private _actions$: Actions
  ) {}
}
