import { Injectable } from "@angular/core";
import { environment } from "@environments/environment";
import {
  AuthenticationDetails,
  CodeDeliveryDetails,
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession,
  ISignUpResult,
} from "amazon-cognito-identity-js";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import * as productActions from "@app/state/product/product.actions";
import * as producerActions from "@app/state/producer/producer.actions";
import { WebsocketService } from "../websocket/web-socket.service";

@Injectable({
  providedIn: "root",
})
export class CognitoService {
  private poolData: any = {
    UserPoolId: environment.CognitoUserPoolId,
    ClientId: environment.CognitoAppClientId,
  };
  private userPool = new CognitoUserPool(this.poolData);

  constructor(
    private router: Router,
    private store: Store,
    private websocketService: WebsocketService
  ) {}

  public getUserPool(): CognitoUserPool {
    return this.userPool;
  }

  public signUp(userForm: any): Promise<ISignUpResult> {
    return new Promise((resolve, reject) => {
      this.userPool.signUp(
        userForm.value.Email,
        userForm.value.Password,
        [],
        [],
        (err, result) => {
          if (err) {
            reject(err.message);
          } else {
            resolve(result);
          }
        }
      );
    });
  }

  public signIn(userForm: any): Promise<CognitoUserSession> {
    let authenticationDetails = new AuthenticationDetails({
      Username: userForm.value.Email,
      Password: userForm.value.Password,
    });
    let userData = {
      Username: userForm.value.Email,
      Pool: this.userPool,
    };
    var cognitoUser = new CognitoUser(userData);

    return new Promise((resolve, reject) => {
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: (result) => {
          resolve(result);
        },
        onFailure: (err) => {
          reject(err.message);
        },
      });
    });
  }

  public signOut(): void {
    let cognitoUser = this.getUserPool().getCurrentUser();
    cognitoUser?.getSession((err: any, result: any) => {
      if (result) {
        cognitoUser.signOut();
        this.store.dispatch(new producerActions.UpdateCurrentProducer(null));
        this.store.dispatch(new productActions.UpdateCurrentProduct(null));
        this.store.dispatch(new productActions.EmptyProductsByProducer());
        this.websocketService.closeWebsocketConnection();
        this.router.navigate(["login"]);
      }
    });
  }

  public getUserSession(): Promise<CognitoUserSession | null> {
    return new Promise((resolve, reject) => {
      const cognitoUser = this.userPool.getCurrentUser();
      if (cognitoUser) {
        cognitoUser.getSession((err: any, session: any) => {
          if (err) {
            console.log("Error retrieving session:", err.message);
            resolve(null);
          } else {
            resolve(session);
          }
        });
      } else {
        resolve(null);
      }
    });
  }

  public async isLoggedIn(): Promise<boolean> {
    let isAuth = false;
    const session = await this.getUserSession();
    if (session) {
      isAuth = session.isValid();
    }
    return isAuth;
  }

  public getUserToken(tokenType: string): Promise<string> {
    return new Promise(async (resolve, reject) => {
      const session = await this.getUserSession();
      if (session && session.isValid()) {
        if (tokenType == "access_token") {
          resolve(session.getAccessToken().getJwtToken());
        } else {
          resolve(session.getIdToken().getJwtToken());
        }
      } else {
        this.signOut();
        resolve(null);
      }
    });
  }

  public forgotPassword(email: string): Promise<CodeDeliveryDetails> {
    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: this.userPool,
    });
    return new Promise((resolve, reject) => {
      cognitoUser.forgotPassword({
        onSuccess: function (result) {
          resolve(result);
        },
        onFailure: function (err) {
          reject(err.message);
        },
      });
    });
  }

  public confirmPassword(
    email: string,
    securityCode: string,
    newPassword: string
  ): Promise<string> {
    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: this.userPool,
    });
    return new Promise((resolve, reject) => {
      cognitoUser.confirmPassword(securityCode, newPassword, {
        onSuccess: function (result) {
          resolve(result);
        },
        onFailure: function (err) {
          reject(err.message);
        },
      });
    });
  }
}
