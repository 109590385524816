import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { CognitoService } from "./services/cognito/cognito.service";
import { Store, select } from "@ngrx/store";
import * as fromRoot from "@app/state";
import { Observable } from "rxjs";
import { IProducer } from "./models/producer.model";
import * as productActions from "@app/state/product/product.actions";
import * as fromProducers from "@app/state/producer";
import * as fromProducts from "@app/state/product";
import { NavigationEnd, Router } from "@angular/router";
import { SwUpdate } from "@angular/service-worker";
import { IProduct } from "./models/product.model";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrl: "./app.component.scss",
})
export class AppComponent implements OnInit {
  currentProducer$: Observable<IProducer>;
  productsByProducer$: Observable<any[]>;
  showProducts: boolean = false;
  showAccount: boolean = false;
  showMenu: boolean = true;
  activeProductId: string | null = null;
  @ViewChild("navbarMenuButton") navbarMenuButton: ElementRef;

  constructor(
    private cognitoService: CognitoService,
    private store: Store<fromRoot.State>,
    private router: Router,
    private swUpdate: SwUpdate,
    private renderer: Renderer2
  ) {
    //refresh the page if the user comes back to the mobile app again
    this.renderer.listen("document", "visibilitychange", () => {
      if (
        this.isMobile() &&
        this.isPWA() &&
        document.visibilityState === "visible"
      ) {
        window.location.reload();
      }
    });
  }

  ngOnInit(): void {
    this._serviceWorkerUpdate();
    this.currentProducer$ = this.store.pipe(
      select(fromProducers.getCurrentProducer)
    );
    if (window.innerWidth < 767) {
      this.showMenu = false;
      if (this.showAccount) this.showAccount = false;
    }
    this.currentProducer$.subscribe(async (currentProducer) => {
      if (currentProducer) {
        if (
          !currentProducer.UpdatedAt &&
          !currentProducer.GeneratedImages &&
          !currentProducer.SocialMediaContents
        ) {
          this.router.navigate(["update-producer"]);
        }
        this.store.dispatch(
          new productActions.GetProductsByProducer(
            currentProducer.OrganizationNumber
          )
        );
      }
    });
    this.productsByProducer$ = this.store.pipe(
      select(fromProducts.getAllProductsByProducer)
    );
    this.scrollToTop();
  }

  //scroll to the top of the page when naviating
  public scrollToTop(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const scrollContainer = document.querySelector(".overflow-y-scroll");
        if (scrollContainer) {
          scrollContainer.scrollTo(0, 0);
        }
      }
    });
  }

  // Check if the app is running as a PWA
  public isPWA(): boolean {
    return (
      window.matchMedia("(display-mode: standalone)").matches ||
      navigator["standalone"] === true
    );
  }

  // Check if the device is mobile
  public isMobile(): boolean {
    const userAgent = navigator.userAgent;
    return /android|iphone|ipad|ipod/i.test(userAgent);
  }

  private _serviceWorkerUpdate(): void {
    if (this.swUpdate.isEnabled) {
      this.swUpdate
        .checkForUpdate()
        .then((res) => {
          if (res && confirm("New version available. Load New Version?")) {
            window.location.reload();
            this.swUpdate.activateUpdate().then(() => {
              document.location.reload();
            });
          }
        })
        .catch((err) => {
          console.error("Error checking for updates", err);
        });
    }
  }

  @HostListener("document:click", ["$event"])
  public onDocumentClick(event: MouseEvent): void {
    const targetElement = event.target as HTMLElement;
    const navbarElement = document.getElementById("navbar");

    // Check if the clicked element is not part of the menu bar or it is a menu item
    if (
      targetElement &&
      navbarElement &&
      (!navbarElement.contains(targetElement) ||
        targetElement.tagName.toLowerCase() == "li")
    ) {
      // Close the menu bar if it is open and if the clicked element is not the navbar menu button
      if (
        !this.navbarMenuButton.nativeElement.contains(targetElement) &&
        this.showMenu
      ) {
        this.showMenu = false;
        if (this.showAccount) this.showAccount = false;
      }
    }
    // Check if the click is outside the account section and products section
    const accountSection = document.querySelector(".mx-4.pt-3.relative");
    const productsSection = document.querySelector(
      ".flex-1.flex.flex-col.justify-start.py-4.mx-4"
    );
    // Check if either dropdown is currently open before closing
    if (
      (this.showAccount &&
        accountSection &&
        !accountSection.contains(targetElement)) ||
      (this.showProducts &&
        productsSection &&
        !productsSection.contains(targetElement))
    ) {
      if (this.showAccount) this.showAccount = false; // Close account dropdown
    }
  }

  public toggleAccount(): void {
    this.showAccount = !this.showAccount;
    if (this.showAccount) {
      this.showProducts = false; // Close products section if account section is opened
    }
  }

  public toggleProducts(): void {
    this.showProducts = !this.showProducts;
    if (this.showProducts) {
      this.showAccount = false; // Close account section if products section is opened
    }
  }

  public viewDetails(route: string, itemId?: string): void {
    this.activeProductId = itemId;
    this.showAccount = false;
    if (itemId) {
      this.router.navigate([route, itemId]);
    } else {
      this.router.navigate([route]);
    }
  }

  public navigateToHomePage(): void {
    this.router.navigate(["products"]);
    this.store.dispatch(new productActions.UpdateCurrentProduct(null));
  }

  public logout(): void {
    this.showAccount = false;
    this.cognitoService.signOut();
  }

  ngOnDestroy(): void {
    // Clean up listener to avoid memory leaks
    this.renderer.listen("document", "visibilitychange", null);
  }
}
