export enum DeleteMode {
  All = "all",
  GeneratedContent = "generatedContent",
  ProfileImage = "profileImage",
}

export enum ImplementationModel {
  ChatGPTMidjourney = "ChatGPT & Midjourney",
  AmazonBedrock = "Amazon Bedrock",
}

export enum GenerationModel {
  Midjourney = "Midjourney",
  Bedrock = "Bedrock",
}

export enum Season {
  Winter = "Winter",
  Spring = "Spring",
  Summer = "Summer",
  Autumn = "Autumn",
}
