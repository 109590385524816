import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Store, select } from "@ngrx/store";
import * as fromRoot from "@app/state";
import { Observable, Subject, takeUntil } from "rxjs";
import { ToastrService } from "ngx-toastr";
import * as fromProducts from "@app/state/product";
import * as fromProducers from "@app/state/producer";
import * as productActions from "@app/state/product/product.actions";
import { IProduct } from "@app/models/product.model";
import { IProducer } from "@app/models/producer.model";

@Component({
  selector: "app-products",
  templateUrl: "./products.component.html",
  styleUrl: "./products.component.scss",
})
export class ProductsComponent implements OnInit, OnDestroy {
  private _onDestroy$ = new Subject<void>();
  productsByProducer$: Observable<any[]>;
  currentProducer$: Observable<IProducer>;

  constructor(
    private router: Router,
    private store: Store<fromRoot.State>,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.displayMessages();
    this.productsByProducer$ = this.store.pipe(
      select(fromProducts.getAllProductsByProducer)
    );
    this.currentProducer$ = this.store.pipe(
      select(fromProducers.getCurrentProducer)
    );
  }

  public displayMessages(): void {
    this.store
      .pipe(select(fromProducts.getSuccessMessage))
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((res) => {
        if (res) {
          this.toastr.success(res);
          this.store.dispatch(new productActions.UpdateSuccessMessage(null));
        }
      });

    this.store
      .pipe(select(fromProducts.getErrorMessage))
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((res) => {
        if (res) {
          this.toastr.error(res);
          this.store.dispatch(new productActions.UpdateErrorMessage(null));
        }
      });
  }

  public calculateDaysSinceUpdate(updatedAt: string): string {
    const now = new Date();
    const updated = updatedAt ? new Date(updatedAt) : new Date();
    const diffInMs: number = now.getTime() - updated.getTime();
    const diffInDays: number = diffInMs / (1000 * 60 * 60 * 24);
    if (Math.floor(diffInDays) == 0) {
      return "Last updated today";
    }
    if (Math.floor(diffInDays) == 1) {
      return "Last updated yesterday";
    } else {
      return "Last updated " + Math.floor(diffInDays) + " days ago";
    }
  }

  public navigateToNewProduct(): void {
    this.router.navigate(["products/new-product"]);
  }

  public viewProductDetails(product: IProduct): void {
    this.router.navigate(["products", product.Id]);
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
