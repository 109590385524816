import { Injectable } from "@angular/core";
import { WebSocketSubject } from "rxjs/webSocket";
import { environment } from "@environments/environment";
import { Store } from "@ngrx/store";
import * as fromRoot from "@app/state";
import * as productActions from "@app/state/product/product.actions";
import * as producerActions from "@app/state/producer/producer.actions";

export interface Message {
  type: string;
  data: any;
}

@Injectable({ providedIn: "root" })
export class WebsocketService {
  public socket$: WebSocketSubject<Message>;
  public websocketUrl: string;
  public isLoggedOut: boolean;

  constructor(private store: Store<fromRoot.State>) {}

  listeners() {
    setInterval(() => {
      //ping the server every 9 minutes to keep the connection alive
      this.socket$.next({
        type: "",
        data: {},
      });
    }, 9 * 60 * 1000);
    this.socket$.subscribe({
      next: (message) => {
        this.setListeners(message);
      },
      error: (err) => {
        if (err) {
          console.log("error in websocket", err);
          alert("Connection lost. Please refresh the page!");
          window.location.reload();
        }
        if (this.websocketUrl) {
          this.socket$ = new WebSocketSubject(this.websocketUrl);
          this.listeners();
        }
      },
      complete: () => {
        //if the user is not logged out, do not close the connection
        if (!this.isLoggedOut) {
          this.socket$ = new WebSocketSubject(this.websocketUrl);
          this.listeners();
        }
      },
    });
  }

  setListeners(message: Message) {
    switch (message.type) {
      case "social-media-content":
        const updateContent = {
          Id: message.data.Id,
          SocialMediaContents: message.data.SocialMediaContents,
        };
        if (message.data.Type == "Producer") {
          this.store.dispatch(
            new producerActions.UpdateProducerFromWebsocket(updateContent)
          );
          alert(
            message.data.Error ||
              `The social media content for producer ${message.data.Name} is generated!`
          );
        } else {
          this.store.dispatch(
            new productActions.UpdateProductFromWebsocket(updateContent)
          );
          alert(
            message.data.Error ||
              `The social media content for product ${message.data.Name} is generated!`
          );
        }
        break;
      case "generated-images":
        const updateImages = {
          Id: message.data.Id,
          GeneratedImages: message.data.GeneratedImages,
        };
        if (message.data.Type == "Producer") {
          this.store.dispatch(
            new producerActions.UpdateProducerFromWebsocket(updateImages)
          );
          alert(
            message.data.Error ||
              `Images for producer ${message.data.Name} are generated!`
          );
        } else {
          this.store.dispatch(
            new productActions.UpdateProductFromWebsocket(updateImages)
          );
          alert(
            message.data.Error ||
              `Images for product ${message.data.Name} are generated!`
          );
        }
        break;
      default:
        break;
    }
  }

  registerWebsocketConnection(orgNumber: string, token: string) {
    const url = `${environment.WebSocketUrl}?authorization=${token}&organization_number=${orgNumber}`;
    this.websocketUrl = url;
    this.socket$ = new WebSocketSubject(url);
    this.listeners();
  }

  closeWebsocketConnection() {
    if (this.socket$) {
      this.isLoggedOut = true;
      this.socket$.complete();
    }
  }
}
