import { Component, Input } from "@angular/core";
import { IProduct } from "@app/models/product.model";
import { MatDialogRef } from "@angular/material/dialog";
import { IProducer } from "@app/models/producer.model";
import { Store } from "@ngrx/store";
import * as fromRoot from "@app/state";
import * as productActions from "@app/state/product/product.actions";
import * as producerActions from "@app/state/producer/producer.actions";
import { S3Service } from "@app/services/s3/s3.service";
import {
  convertFileToBase64,
  getS3FolderNameForProduct,
  getUniqueImageName,
} from "@app/utils/helpers";
import { DeleteMode } from "@app/utils/enums";
import { environment } from "@environments/environment";
import { IDeleteImagesInS3, IUpdateItem } from "@app/models/shared.model";

@Component({
  selector: "app-dialog-choose-photo",
  templateUrl: "./dialog-choose-photo.component.html",
  styleUrl: "./dialog-choose-photo.component.scss",
})
export class DialogChoosePhotoComponent {
  @Input() currentItem: IProduct | IProducer;
  @Input() dialogTitle: string = "";
  chosenPhoto: any = null;
  photoUrl: string = "";

  constructor(
    private dialogRef: MatDialogRef<DialogChoosePhotoComponent>,
    private store: Store<fromRoot.State>,
    private s3Service: S3Service
  ) {}

  public onPhotoUpload(event): void {
    if (event && event.target && event.target.files && event.target.files[0]) {
      this.chosenPhoto = event.target.files[0];
      this.photoUrl = URL.createObjectURL(this.chosenPhoto);
      event.target.value = "";
    }
  }

  public async saveChosenPhoto(): Promise<void> {
    if (this.chosenPhoto && this.currentItem) {
      let newImageName = null;
      if (this.chosenPhoto instanceof File) {
        //set the image name to be timestamp.[jpg,jpeg,png,etc depending of the chosen image]
        newImageName = getUniqueImageName(this.chosenPhoto.name);
        const base64String = await convertFileToBase64(this.chosenPhoto);
        this.chosenPhoto = base64String;
      } else {
        //set the image name to be timestamp.[jpg,jpeg,png,etc depending of the chosen image]
        newImageName = getUniqueImageName(this.chosenPhoto);
      }
      const oldProfileImage = this.currentItem.Photo
        ? this.currentItem.Photo.split("/").pop()
        : null;

      let deleteOldImage = null;
      if (oldProfileImage) {
        deleteOldImage = {
          DeleteMode: DeleteMode.ProfileImage,
          Bucket: environment.BucketName,
          Keywords: [oldProfileImage],
        };
      }
      let updateItem = {
        Id: this.currentItem.Id,
        OrganizationNumber: this.currentItem.OrganizationNumber,
      };
      //if we are updating products
      if (!("CompanyName" in this.currentItem)) {
        this.updateProductPhoto(newImageName, updateItem, deleteOldImage);
      } //if we are updating producers
      else {
        this.updateProducerPhoto(newImageName, updateItem, deleteOldImage);
      }
    }
    this.dialogRef.close();
  }

  public async updateProducerPhoto(
    newImageName: string,
    updateItem: IUpdateItem,
    deleteOldImage: IDeleteImagesInS3
  ): Promise<void> {
    const subFolderName = this.currentItem.OrganizationNumber;
    await this.s3Service
      .imageUpload(this.chosenPhoto, "Producers", subFolderName, newImageName)
      .then((image) => {
        updateItem.Photo = image;
      });
    this.store.dispatch(new producerActions.UpdateProducer(updateItem, false));
    if (deleteOldImage) {
      deleteOldImage.FolderName = this.currentItem.OrganizationNumber;
      deleteOldImage.Prefix = "Producers/";
      // Dispatch action to delete old image from S3
      this.store.dispatch(
        new producerActions.DeleteProducerImagesInS3(deleteOldImage)
      );
    }
  }

  public async updateProductPhoto(
    newImageName: string,
    updateItem: IUpdateItem,
    deleteOldImage: IDeleteImagesInS3
  ): Promise<void> {
    const subFolderName = getS3FolderNameForProduct(
      this.currentItem["Name"],
      this.currentItem["CreatedAt"]
    );
    await this.s3Service
      .imageUpload(this.chosenPhoto, "Products", subFolderName, newImageName)
      .then((image) => {
        updateItem.Photo = image;
      });
    this.store.dispatch(new productActions.UpdateProduct(updateItem));
    //delete the profile image if it was updated
    if (deleteOldImage) {
      deleteOldImage.FolderName = subFolderName;
      deleteOldImage.Prefix = "Products/";
      // Dispatch action to delete old image from S3
      this.store.dispatch(
        new productActions.DeleteProductImagesInS3(deleteOldImage)
      );
    }
  }
}
