import { Action } from "@ngrx/store";

export enum ProductActionTypes {
  GetProductsByProducer = "[Product] Get Products By Producer",
  GetProductsByProducerSuccess = "[Product] Get Products By Producer Success",
  GetProductsByProducerFailed = "[Product] Get Products By Producer Failed",

  CreateProduct = "[Product] Create Product",
  CreateProductSuccess = "[Product] Create Product Success",
  CreateProductFailed = "[Product] Create Product Failed",

  DeleteProduct = "[Product] Delete Product",
  DeleteProductSuccess = "[Product] Delete Product Success",
  DeleteProductFailed = "[Product] Delete Product Failed",

  UpdateSuccessMessage = "[Product] Update Success Message",
  UpdateErrorMessage = "[Product] Update Error Message",

  UpdateCurrentProduct = "[Product] Update Current Product",

  EmptyProductsByProducer = "[Product] Empty Products By Producer",

  UpdateProductFromWebsocket = "[Product] Update Product From Websocket",

  UpdateProduct = "[Product] Update Product ",
  UpdateProductSuccess = "[Product] Update Product Success",
  UpdateProductFailed = "[Product] Update Product  Failed",

  RegenerateProduct = "[Product] Regenerate Product ",
  RegenerateProductSuccess = "[Product] Regenerate Product Success",
  RegenerateProductFailed = "[Product] Regenerate Product  Failed",

  DeleteProductImagesInS3 = "[Product] Delete Product Images in S3",
  DeleteProductImagesInS3Success = "[Product] Delete Product Images in S3 Success",
  DeleteProductImagesInS3Failed = "[Product] Delete Product Images in S3 Failed",
}

export class GetProductsByProducer implements Action {
  readonly type = ProductActionTypes.GetProductsByProducer;

  constructor(public payload: any) {}
}

export class GetProductsByProducerSuccess implements Action {
  readonly type = ProductActionTypes.GetProductsByProducerSuccess;

  constructor(public payload: any) {}
}

export class GetProductsByProducerFailed implements Action {
  readonly type = ProductActionTypes.GetProductsByProducerFailed;

  constructor(public payload: any) {}
}

export class CreateProduct implements Action {
  readonly type = ProductActionTypes.CreateProduct;

  constructor(
    public payload: any,
    public producer: any,
    public implementation: string
  ) {}
}

export class CreateProductSuccess implements Action {
  readonly type = ProductActionTypes.CreateProductSuccess;

  constructor(public payload: any) {}
}

export class CreateProductFailed implements Action {
  readonly type = ProductActionTypes.CreateProductFailed;

  constructor(public payload: any) {}
}

export class DeleteProduct implements Action {
  readonly type = ProductActionTypes.DeleteProduct;

  constructor(public payload: any) {}
}

export class DeleteProductSuccess implements Action {
  readonly type = ProductActionTypes.DeleteProductSuccess;

  constructor(public payload: any) {}
}

export class DeleteProductFailed implements Action {
  readonly type = ProductActionTypes.DeleteProductFailed;

  constructor(public payload: any) {}
}

export class UpdateErrorMessage implements Action {
  readonly type = ProductActionTypes.UpdateErrorMessage;

  constructor(public payload: any) {}
}

export class UpdateSuccessMessage implements Action {
  readonly type = ProductActionTypes.UpdateSuccessMessage;

  constructor(public payload: any) {}
}

export class UpdateCurrentProduct implements Action {
  readonly type = ProductActionTypes.UpdateCurrentProduct;

  constructor(public payload: any) {}
}

export class EmptyProductsByProducer implements Action {
  readonly type = ProductActionTypes.EmptyProductsByProducer;

  constructor() {}
}

export class UpdateProductFromWebsocket implements Action {
  readonly type = ProductActionTypes.UpdateProductFromWebsocket;

  constructor(public payload: any) {}
}
export class UpdateProduct implements Action {
  readonly type = ProductActionTypes.UpdateProduct;

  constructor(public payload: any) {}
}
export class UpdateProductSuccess implements Action {
  readonly type = ProductActionTypes.UpdateProductSuccess;
  constructor(public payload: any) {}
}
export class UpdateProductFailed implements Action {
  readonly type = ProductActionTypes.UpdateProductFailed;
  constructor(public payload: any) {}
}

export class RegenerateProduct implements Action {
  readonly type = ProductActionTypes.RegenerateProduct;

  constructor(
    public payload: any,
    public producer: any,
    public implementation: string
  ) {}
}
export class RegenerateProductSuccess implements Action {
  readonly type = ProductActionTypes.RegenerateProductSuccess;
  constructor(public payload: any) {}
}
export class RegenerateProductFailed implements Action {
  readonly type = ProductActionTypes.RegenerateProductFailed;
  constructor(public payload: any) {}
}
export class DeleteProductImagesInS3 implements Action {
  readonly type = ProductActionTypes.DeleteProductImagesInS3;

  constructor(public payload: any) {}
}
export class DeleteProductImagesInS3Success implements Action {
  readonly type = ProductActionTypes.DeleteProductImagesInS3Success;
  constructor(public payload: any) {}
}
export class DeleteProductImagesInS3Failed implements Action {
  readonly type = ProductActionTypes.DeleteProductImagesInS3Failed;
  constructor(public payload: any) {}
}

export type ProductActions =
  | GetProductsByProducer
  | GetProductsByProducerSuccess
  | GetProductsByProducerFailed
  | CreateProduct
  | CreateProductSuccess
  | CreateProductFailed
  | DeleteProduct
  | DeleteProductSuccess
  | DeleteProductFailed
  | UpdateSuccessMessage
  | UpdateErrorMessage
  | UpdateCurrentProduct
  | EmptyProductsByProducer
  | UpdateProductFromWebsocket
  | UpdateProduct
  | UpdateProductSuccess
  | UpdateProductFailed
  | RegenerateProduct
  | RegenerateProductSuccess
  | RegenerateProductFailed
  | DeleteProductImagesInS3
  | DeleteProductImagesInS3Success
  | DeleteProductImagesInS3Failed;
