import { NgModule, isDevMode } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { ClipboardModule } from "@angular/cdk/clipboard";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ServiceWorkerModule } from "@angular/service-worker";
import { ReactiveFormsModule } from "@angular/forms";
import { ToastrModule } from "ngx-toastr";
import { ProductsComponent } from "./components/products/products.component";
import { ForgotPasswordComponent } from "./components/auth/forgot-password/forgot-password.component";
import { LoginComponent } from "./components/auth/login/login.component";
import { RegisterComponent } from "./components/auth/register/register.component";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { effects } from "./state/effects";
import { GraphQLModule } from "./services/graphql/graphql.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { reducers } from "./state/reducers";
import { NewProductComponent } from "./components/products/new-product/new-product.component";
import { ProductDetailsComponent } from "./components/products/product-details/product-details.component";
import { DialogConfirmationComponent } from "./dialogs/dialog-confirmation/dialog-confirmation.component";
import { MatDialogModule } from "@angular/material/dialog";
import { MatButtonModule } from "@angular/material/button";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { CognitoInterceptor } from "./services/cognito/cognito.interceptor";
import { UpdateProducerComponent } from "./components/producers/update-producer/update-producer.component";
import { ProducerDetailsComponent } from "./components/producers/producer-details/producer-details.component";
import { IntroductionComponent } from "./components/introduction/introduction.component";
import { DialogChoosePhotoComponent } from "./dialogs/dialog-choose-photo/dialog-choose-photo.component";
import { SocialMediaComponent } from './components/introduction/social-media/social-media.component';
import { AccountDetailsComponent } from './components/account-details/account-details.component';

@NgModule({
  declarations: [
    AppComponent,
    ProductsComponent,
    ForgotPasswordComponent,
    LoginComponent,
    RegisterComponent,
    NewProductComponent,
    ProductDetailsComponent,
    DialogConfirmationComponent,
    DialogChoosePhotoComponent,
    UpdateProducerComponent,
    ProducerDetailsComponent,
    IntroductionComponent,
    SocialMediaComponent,
    AccountDetailsComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MatProgressSpinnerModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    GraphQLModule,
    MatDialogModule,
    MatButtonModule,
    ClipboardModule,
    StoreModule.forRoot(reducers),
    EffectsModule.forRoot(effects),
    ToastrModule.forRoot({ positionClass: "toast-bottom-right" }),
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: "registerWhenStable:30000",
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CognitoInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
