import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Store, select } from "@ngrx/store";
import * as fromRoot from "@app/state";
import * as productActions from "@app/state/product/product.actions";
import { IProduct } from "@app/models/product.model";
import * as fromProduct from "@app/state/product";
import * as fromProducer from "@app/state/producer";
import { Subject, takeUntil } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { DialogConfirmationComponent } from "@app/dialogs/dialog-confirmation/dialog-confirmation.component";
import { environment } from "@environments/environment";
import { Clipboard } from "@angular/cdk/clipboard";
import { IProducer } from "@app/models/producer.model";
import { DeleteMode, GenerationModel } from "@app/utils/enums";
import { DialogChoosePhotoComponent } from "@app/dialogs/dialog-choose-photo/dialog-choose-photo.component";
import { ISocialMediaContent } from "@app/models/content.model";
import { getS3FolderNameForProduct } from "@app/utils/helpers";

@Component({
  selector: "app-product-details",
  templateUrl: "./product-details.component.html",
})
export class ProductDetailsComponent implements OnInit, OnDestroy {
  currentProduct: IProduct;
  currentProducer: IProducer;
  private _onDestroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private store: Store<fromRoot.State>,
    private dialog: MatDialog,
    private clipboard: Clipboard
  ) {}

  ngOnInit(): void {
    this.store
      .pipe(select(fromProduct.getCurrentProduct), takeUntil(this._onDestroy$))
      .subscribe((res) => {
        this.currentProduct = res;
      });
    this.store
      .pipe(
        select(fromProducer.getCurrentProducer),
        takeUntil(this._onDestroy$)
      )
      .subscribe((res) => {
        this.currentProducer = res;
      });
  }

  public getGeneratedImagesCount(): number {
    return this.currentProduct.GeneratedImages
      ? this.currentProduct.GeneratedImages?.filter((item) => item !== "")
          ?.length
      : 0;
  }

  public navigateToProducts(): void {
    this.router.navigate(["products"]);
    this.store.dispatch(new productActions.UpdateCurrentProduct(null));
  }

  public regenerateProductContent(): void {
    const dialogRef = this.dialog.open(DialogConfirmationComponent);
    dialogRef.componentInstance.title = "Confirm Regeneration";
    dialogRef.componentInstance.message =
      "If you regenerate this product, all the current content and images will be replaced. Are you sure you want to go ahead?";
    dialogRef.afterClosed().subscribe((confirmed) => {
      if (confirmed && this.currentProduct && this.currentProducer) {
        const updateObj = {
          Id: this.currentProduct.Id,
          GeneratedImages: [],
          SocialMediaContents: [],
          OrganizationNumber: this.currentProducer.OrganizationNumber,
        };
        this.store.dispatch(new productActions.UpdateProduct(updateObj));
        // Construct event object for deleting images
        const event = {
          FolderName: getS3FolderNameForProduct(
            this.currentProduct.Name,
            this.currentProduct.CreatedAt
          ),
          DeleteMode: DeleteMode.GeneratedContent, // Specify 'all', 'generatedContent', or 'profileImage'
          Bucket: environment.BucketName,
          Prefix: "Products/", //TODO make it more dynamic
          Keywords: [GenerationModel.Midjourney, GenerationModel.Bedrock], // Add specific keywords if needed
        };

        // Dispatch action to delete images from S3
        this.store.dispatch(new productActions.DeleteProductImagesInS3(event));

        this.store.dispatch(
          new productActions.RegenerateProduct(
            this.currentProduct,
            this.currentProducer,
            this.currentProduct.Implementation
          )
        );
        setTimeout(() => {
          alert(
            `The social media content and images for "${this.currentProduct.Name}" are being regenerated. We’ll notify you when it’s done!`
          );
        }, 1000);
      }
    });
  }

  public deleteProduct(): void {
    if (this.currentProduct) {
      const dialog = this.dialog.open(DialogConfirmationComponent);
      dialog.componentInstance.title = "Delete product";
      dialog.componentInstance.message = `All the generated content and images will be deleted. Are you sure you want to delete this product?`;
      dialog.afterClosed().subscribe((res) => {
        if (res) {
          let payload = {
            Id: this.currentProduct.Id,
            OrganizationNumber: this.currentProducer?.OrganizationNumber,
            Name: this.currentProduct.Name,
            CreatedAt: this.currentProduct.CreatedAt,
            Photo: this.currentProduct.Photo,
            GeneratedImages: this.currentProduct.GeneratedImages,
          };
          this.store.dispatch(new productActions.DeleteProduct(payload));
          this.store.dispatch(new productActions.UpdateCurrentProduct(null));
          this.router.navigate(["products"]);
        }
      });
    }
  }

  public copyContent(socialMedia: ISocialMediaContent) {
    let copyText =
      "Title: " +
      socialMedia.Content.Title +
      "\n Description: " +
      socialMedia.Content.Description;
    if (socialMedia.Platform == "Twitter") {
      copyText = socialMedia.Content.Description;
    }
    this.clipboard.copy(copyText);
  }

  public openDialogForChoosingPhoto(): void {
    if (this.currentProduct) {
      const dialog = this.dialog.open(DialogChoosePhotoComponent);
      dialog.componentInstance.dialogTitle = "Choose product photo";
      dialog.componentInstance.currentItem = this.currentProduct;
    }
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
